import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  header: {
    fontSize: '3.25rem',
    padding: '40px 50px 50px 100px',
  },
  icon: {
    width: '170px',
    height: '170px',
    borderRadius: '50%',
    padding: '40px',
    margin: '0 20px 0 20px',
    boxShadow: '3px 3px 10px lightgrey',
  },
  radioactive: {
    backgroundColor: '#faca66',
  },
  equipment: {
    backgroundColor: '#44c5bb',
  },
  tools: {
    backgroundColor: '#f96c5d',
  },
  services: {
    marginTop: '85px',
    marginBottom: '85px',
    textAlign: 'center',
  },
  listItem: {
    paddingLeft: '150px',
  },
  bulletPoint: {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    background: '#3ec5bb',
  },
});

export default useStyles;
