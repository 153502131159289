import React from 'react';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
} from '@material-ui/core';
import useStyles from './ServiciosStyles';

// SVGs
import Radioactive from '../../../assets/icons/radioactive_white.svg';
import Dosimetro from '../../../assets/icons/Dosimetro.svg';
import Tools from '../../../assets/icons/herramientas_white.svg';

const Servicios = () => {
  const classes = useStyles();
  return (
    <Grid id="servicios" container>
      <Grid container direction="row" justifyContent="space-between">
        <Typography variant="h2" className={classes.header}>
          Nuestros <strong>servicios</strong>
        </Typography>
        <Grid container item xs={6} direction="row" justifyContent="flex-start">
          <Grid item className={`${classes.icon} ${classes.radioactive}`}>
            <img src={Radioactive} width="90px" height="90px"></img>
          </Grid>
          <Grid item className={`${classes.icon} ${classes.equipment}`}>
            <img src={Dosimetro} width="90px" height="90px"></img>
          </Grid>
          <Grid item className={`${classes.icon} ${classes.tools}`}>
            <img src={Tools} width="90px" height="90px"></img>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="column"
        xs={6}
        className={classes.services}
      >
        <List>
          <ListItem className={classes.listItem}>
            <ListItemIcon>
              <div className={classes.bulletPoint}></div>
            </ListItemIcon>
            <Typography variant="h6">
              Cursos de protección radiológica
            </Typography>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemIcon>
              <div className={classes.bulletPoint}></div>
            </ListItemIcon>
            <Typography variant="h6">Levantamiento de niveles</Typography>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemIcon>
              <div className={classes.bulletPoint}></div>
            </ListItemIcon>
            <Typography variant="h6">Memorias analíticas</Typography>
          </ListItem>
        </List>
      </Grid>
      <Grid
        container
        item
        direction="column"
        xs={6}
        className={classes.services}
      >
        <List>
          <ListItem>
            <ListItemIcon>
              <div className={classes.bulletPoint}></div>
            </ListItemIcon>
            <Typography variant="h6">Dosimetría</Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <div className={classes.bulletPoint}></div>
            </ListItemIcon>
            <Typography variant="h6">
              Venta y reparación de equipo de imagenologia y rayos X
            </Typography>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <div className={classes.bulletPoint}></div>
            </ListItemIcon>
            <Typography variant="h6">
              Asesoría en seguridad radiológica
            </Typography>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default Servicios;
