import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    lineHeight: '2.5rem',
  },
  docDescription: {
    marginTop: '1.8rem'
  },

  outlinedButton: {
    textTransform: 'none',
    border: `2px solid ${theme.palette.secondary.main}`,
    padding: '0.75rem',
    margin: '0 0.35rem',
    minWidth: '8rem',
  },
  filledButton: {
    textTransform: 'none',
    fontWeight: 'bold',
    color: 'white',
    padding: '0.875rem 2.5rem',
    margin: '0 0.35rem',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  bullet: {
    fontSize: '1.25rem',
    color: theme.palette.primary.main,
    margin: '0 1.4rem',
  },
  docProps: {
    fontSize: '1.25rem',
  },
  text: {
    margin: '5.25rem 0',
  },
}));

export default useStyles;
