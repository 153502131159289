import { db, storage } from '../Firebase';

const reportsRef = db.collection('Reports');

export default class Report {
  // prettier-ignore
  constructor(clientId, institution, createdAt, createdBy, invoice, service, text, date, fileUrl = "", signed = false, machine = "") {
    this.clientId = clientId;
    this.institution = institution;
    this.createdAt = createdAt;
    this.createdBy = createdBy;
    this.invoice = invoice;
    this.service = service;
    this.text = text;
    this.date = date;
    this.signed = signed;
    this.machine = machine;
    this.fileUrl = fileUrl
  }

  /**
   * Method to convert the Report to an object.
   * @returns A javascript object containing all of the properties of the Report.
   */
  toObject() {
    return {
      clientId: this.clientId,
      institution: this.institution,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      invoice: this.invoice,
      service: this.service,
      text: this.text,
      date: this.date,
      signed: this.signed,
      machine: this.machine,
      fileUrl: this.fileUrl,
    };
  }
}

const reportConverter = {
  toFirestore: (report) => {
    return {
      clientId: report.clientId,
      institution: report.institution,
      createdAt: report.createdAt,
      createdBy: report.createdBy,
      invoice: report.invoice,
      service: report.service,
      text: report.text,
      date: report.date,
      signed: report.signed,
      fileUrl: report.fileUrl,
      machine: report.machine,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    // prettier-ignore
    return new Report(data.clientId, data.institution, data.createdAt, data.createdBy, data.invoice, data.service, data.text, data.date, data.fileUrl, data.signed, data.machine);
  },
};

export const createReport = (report) => {
  return new Promise((resolve, reject) => {
    reportsRef
      .withConverter(reportConverter)
      .add(report)
      .then((reportRef) => {
        resolve(reportRef.id);
      })
      .catch((error) => reject(error));
  });
};

export const editReport = (id, propertiesToUpdate) => {
  return new Promise((resolve, reject) => {
    reportsRef
      .doc(id)
      .update(propertiesToUpdate)
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const uploadFile = (file) => {
  return new Promise(async (resolve, reject) => {
    const fileRef = storage.ref().child(`Reports/${file.name}`);
    fileRef
      .put(file)
      .then((snapshot) => {
        resolve(snapshot);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const fetchReportById = (reportId) => {
  return new Promise((resolve, reject) => {
    reportsRef
      .doc(reportId)
      .withConverter(reportConverter)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const report = { id: doc.id, ...doc.data() };
          resolve(report);
        } else {
          // doc.data() will be undefined in this case
          reject('No document was found');
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchReportsCollection = () => {
  return new Promise((resolve, reject) => {
    reportsRef
      .get()
      .then((querySnapshot) => {
        const docArray = [];
        querySnapshot.forEach((doc) => {
          docArray.push({ id: doc.id, ...doc.data() });
        });
        resolve(docArray);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const listenReportsCollection = (callback) => {
  const unsubscribe = reportsRef.onSnapshot((snapshot) => {
    callback(snapshot);
  });

  return unsubscribe;
};

export const fetchReportsByQuery = (attribute, operator, value) => {
  return new Promise((resolve, reject) => {
    reportsRef
      .where(attribute, operator, value)
      .get()
      .then((querySnapshot) => {
        resolve(querySnapshot);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteReport = (id) => {
  return new Promise((resolve, reject) => {
    reportsRef
      .doc(id)
      .delete()
      .then(() => resolve())
      .catch((error) => reject(error));
  });
};
