import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  divider: {
    backgroundColor: '#3ec5bb',
    margin: '50px auto 50px auto',
    width: '90%',
  },
});

export default useStyles;
