import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  topContainer: {
    width: '100%',
  },
  searchInputContainer: {
    margin: '0.75rem',
  },
  searchInput: {
    width: '100%',
  },
  searchButton: {
    height: '3rem',
    textTransform: 'none',
    color: 'white',
    backgroundColor: `${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.main}`,
    },
  },
}));

export default useStyles;
