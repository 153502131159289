import React, { useState } from 'react';
import {
  Grid,
  Button,
  Typography,
  Avatar,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useStyles from './CreateClientStyles';
import PublishIcon from '@material-ui/icons/Publish';
import { useAuth } from '../../../../Contexts/AuthContext';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { DropzoneDialog } from 'material-ui-dropzone';
import Notification from '../../../../Helpers/Notification';
import { storage } from '../../../../Utilities/Firebase';
import { regexMail, onlyChar, onlyNum } from '../../../../Helpers/Regex';

const CreateClient = ({ setCreateUser }) => {
  const classes = useStyles();

  const { signUpClient } = useAuth();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState('password');

  const [services, setServices] = useState([]);
  const [servicesBool, setServicesBool] = useState({
    dosimetria: false,
    rayosx: false,
    mantenimiento: false,
    capacitaciones: false,
    certificados: false,
    asesorias: false,
    venta: false,
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  const [data, setData] = useState({
    adminUser: false,
    institution: '',
    email: '',
    name: '',
    lastName: '',
    cellPhone: '',
    phone: '',
    address: '',
    password: '',
  });

  const correctData = {
    institution: true,
    address: true,
    email: true,
    name: true,
    lastName: true,
    cellPhone: true,
    phone: true,
    password: true,
  };

  const handleChange = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleCheck = (e) => {
    e.preventDefault();
    let name = e.target.name;
    setServicesBool({ ...servicesBool, [name]: e.target.checked });
    if (e.target.checked) {
      setServices([...services, name]);
    } else {
      setServices(services.filter((item) => item !== name));
    }
    console.log(servicesBool)
  };

  const onClickverifyData = (data, services,servicesBool) => {
    if (!data.password) {
      setNotify({
        isOpen: true,
        message: 'Campo de contraseña no puede estar vacío',
        type: 'error',
      });
      correctData.password = false;
    } else if (data.password.length < 6) {
      setNotify({
        isOpen: true,
        message: 'La contraseña debe de ser mayor a 6 caracteres',
        type: 'error',
      });
      correctData.password = false;
    } else {
      correctData.password = true;
    }

    if (!data.email) {
      setNotify({
        isOpen: true,
        message: 'Campo de correo no puede estar vacío',
        type: 'error',
      });
      correctData.email = false;
    } else if (!regexMail.test(data.email)) {
      setNotify({
        isOpen: true,
        message: 'El correo tiene un formato no permitido',
        type: 'error',
      });
      correctData.email = false;
    } else {
      correctData.email = true;
    }

    if (!data.institution) {
      setNotify({
        isOpen: true,
        message: 'Campo de Institución no puede estar vacío',
        type: 'error',
      });
      correctData.institution = false;
    } else {
      correctData.institution = true;
    }

    if (!data.address) {
      setNotify({
        isOpen: true,
        message: 'Campo de direccion no puede estar vacío',
        type: 'error',
      });
      correctData.address = false;
    } else {
      correctData.address = true;
    }

    if (!data.name) {
      setNotify({
        isOpen: true,
        message: 'Campo de nombre no puede estar vacío',
        type: 'error',
      });
      correctData.name = false;
    } else if (!onlyChar.test(data.name)) {
      setNotify({
        isOpen: true,
        message: 'Solo se pueden colocar letras en el nombre',
        type: 'error',
      });
      correctData.name = false;
    } else {
      correctData.name = true;
    }

    if (!data.lastName) {
      setNotify({
        isOpen: true,
        message: 'Campo de apellido no puede estar vacío',
        type: 'error',
      });
      correctData.lastName = false;
    } else if (!onlyChar.test(data.lastName)) {
      setNotify({
        isOpen: true,
        message: 'Solo se pueden colocar letras en el apellido',
        type: 'error',
      });
      correctData.lastName = false;
    } else {
      correctData.lastName = true;
    }

    if (!data.phone) {
      setNotify({
        isOpen: true,
        message: 'Campo de telefono no puede estar vacío',
        type: 'error',
      });
      correctData.phone = false;
    } else if (!onlyNum.test(data.phone)) {
      setNotify({
        isOpen: true,
        message: 'Solo pueden colocar numeros en el telefono',
        type: 'error',
      });
      correctData.phone = false;
    } else {
      correctData.phone = true;
    }

    if (!data.cellPhone) {
      setNotify({
        isOpen: true,
        message: 'Campo de celular no puede estar vacío',
        type: 'error',
      });
      correctData.cellPhone = false;
    } else if (!onlyNum.test(data.cellPhone)) {
      setNotify({
        isOpen: true,
        message: 'Solo pueden colocar numeros en el celular',
        type: 'error',
      });
      correctData.cellPhone = false;
    } else {
      correctData.cellPhone = true;
    }

    if (
      correctData.email &&
      correctData.password &&
      correctData.name &&
      correctData.lastName &&
      correctData.institution &&
      correctData.cellPhone &&
      correctData.phone
    ) {
      signUpClient(data, services,servicesBool, setCreateUser, history);
    }
  };

  const uploadHandler = async (files) => {
    const image = files[0];

    const uploadRef = storage.ref().child(`AvatarImage/${image.name}`);

    await uploadRef
      .put(image)
      .then(() => {
        setNotify({
          isOpen: true,
          message: 'La imagen se subio correctamente ',
          type: 'success',
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            'Hubo un problema a al tratar de subir la imagen, intentalo mas tarde ',
          type: 'error',
        });
        console.error(error);
      });

    await uploadRef.getDownloadURL().then((url) => {
      setData({ ...data, profilePic: url });
    });
  };

  return (
    <Grid container item className={classes.root}>
      <Grid container item direction="row" xs={12}>
        <Grid item xs={6}>
          <Typography className={classes.createTitle}>Cliente nuevo</Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.buttonAqua}
            onClick={() => {
              onClickverifyData(data, services,servicesBool);
            }}
          >
            Crear cliente
          </Button>
          <Button
            className={classes.cancelButton}
            onClick={() => {
              setCreateUser(false);
              history.push('/dashboard/clientes');
            }}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
      <Grid item style={{ paddingBottom: '20px' }}>
        <Typography className={classes.title}>Información</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item direction="column" xs={3}>
          <Grid container>
            <Avatar className={classes.avatar}>
              <PublishIcon className={classes.iconSize} />
            </Avatar>
          </Grid>
          <Grid container>
            <Button
              variant="contained"
              component="label"
              className={classes.buttonImg}
              onClick={() => setOpen(true)}
            >
              Subir foto
            </Button>
            <DropzoneDialog
              acceptedFiles={['image/*']}
              cancelButtonText={'cancel'}
              submitButtonText={'submit'}
              maxFileSize={5000000}
              open={open}
              onClose={() => setOpen(false)}
              onSave={(files) => {
                uploadHandler(files);
                setOpen(false);
              }}
              showPreviews={true}
              showFileNamesInPreview={true}
            />
          </Grid>
        </Grid>
        <Grid container item direction="column" xs={5}>
          <Grid item>
            <Typography className={classes.subtitle}>
              Correo electronico
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              required
              InputProps={{
                className: classes.dataField,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className={classes.dataField}
              value={data.email}
              type="email"
              name="email"
              onChange={handleChange}
            ></TextField>
          </Grid>
          <Grid item>
            <Typography className={classes.subtitle}>Contraseña</Typography>
          </Grid>
          <Grid item>
            <TextField
              required
              InputProps={{
                className: classes.dataField,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className={classes.dataField}
              value={data.password}
              type={showPassword}
              name="password"
              onChange={handleChange}
            ></TextField>
            <Button
              className={classes.viewButton}
              onClick={() => {
                showPassword === 'password'
                  ? setShowPassword('text')
                  : setShowPassword('password');
              }}
            >
              {showPassword === 'password' ? (
                <VisibilityOffIcon />
              ) : (
                <VisibilityIcon />
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="row"
        xs={12}
        className={classes.container}
      >
        <Grid container item direction="column" xs={6}>
          <Grid item>
            <Typography className={classes.subtitle}>
              Información personal
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction row>
              <Grid item irection="column">
                <Typography className={classes.text}>Institución</Typography>
                <Typography className={classes.text}>
                  Direccion Institucion
                </Typography>
                <Typography className={classes.text}>Nombre</Typography>
                <Typography className={classes.text}>Apellidos</Typography>
                <Typography className={classes.text}>Telefono</Typography>
                <Typography className={classes.text}>Celular</Typography>
              </Grid>
              <Grid item direction="column">
                <Grid item>
                  <TextField
                    required
                    type="text"
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className={classes.dataField}
                    value={data.institution}
                    name="institution"
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid>
                  <TextField
                    required
                    type="text"
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className={classes.dataField}
                    value={data.address}
                    name="address"
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid>
                  <TextField
                    required
                    type="text"
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className={classes.dataField}
                    value={data.name}
                    name="name"
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid>
                  <TextField
                    required
                    type="text"
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className={classes.dataField}
                    value={data.lastName}
                    name="lastName"
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid>
                  <TextField
                    required
                    type="text"
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className={classes.dataField}
                    value={data.phone}
                    name="phone"
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid>
                  <TextField
                    required
                    type="text"
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className={classes.dataField}
                    value={data.cellPhone}
                    name="cellPhone"
                    onChange={handleChange}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item direction="column" xs={6} className = {classes.servicios}>
          <Grid item>
            <FormControl name="services">
              <Typography className={classes.subtitle}>Servicio</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={services.dosimetria}
                      onChange={handleCheck}
                      name="dosimetria"
                    />
                  }
                  label="Dosimetría"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={services.rayosx}
                      onChange={handleCheck}
                      name="rayosx"
                    />
                  }
                  label="Rayos-X"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={services.capacitaciones}
                      onChange={handleCheck}
                      name="capacitaciones"
                    />
                  }
                  label="Capacitaciones"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={services.certificados}
                      onChange={handleCheck}
                      name="certificados"
                    />
                  }
                  label="Certificados"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={services.asesorias}
                      onChange={handleCheck}
                      name="asesorias"
                    />
                  }
                  label="Asesorias"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={services.venta}
                      onChange={handleCheck}
                      name="venta"
                    />
                  }
                  label="Venta de equipo"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={services.mantenimiento}
                      onChange={handleCheck}
                      name="mantenimiento"
                    />
                  }
                  label="Mantenimiento"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} position={'bottom'} />
    </Grid>
  );
};

export default CreateClient;
