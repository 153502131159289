import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '2rem',
  },
  subheader: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    margin: '2rem 0 3.75rem 0',
  },
  messageContainer: {
    marginBottom: '0.625rem',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '0.4rem',
    minHeight: '7.5rem',
    cursor: 'pointer',
  },
  avatar: {
    height: '3.7rem',
    width: '3.7rem',
    margin: '1.84rem 1.6rem',
  },
  messageText: {
    marginTop: '0.625rem',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  time: {
    fontSize: '0.8rem',
    color: 'rgba(0, 0, 0, 0.6)',
    margin: 'auto',
  },
}));

export default useStyles;
