import React from 'react';
import { Grid, Typography, Avatar } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import useStyles from './FooterStyles';

// Icons
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneInTalkOutlinedIcon from '@material-ui/icons/PhoneInTalkOutlined';
import FacebookIcon from '@material-ui/icons/Facebook';
import MailOutlinedIcon from '@material-ui/icons/MailOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';

const Footer = () => {
  const classes = useStyles();
  return (
    <>
      <Typography id="contacto" variant="h2" className={classes.header}>
        Contacto <strong>Imagenología Méndex</strong>
      </Typography>
      <Typography id="contacto" variant="h2" className={classes.subheader}>
        Sucursal Chihuahua
      </Typography>
      <Grid container className={classes.contact}>
        <Grid item xs={6}>
          <List>
            <ListItem className={classes.listItem}>
              <ListItemAvatar>
                <Avatar className={classes.listItemAvatar}>
                  <WhatsAppIcon className={classes.icon} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="WhatsApp"
                primaryTypographyProps={{
                  className: `${classes.listItemText} ${classes.listItemHeader}`,
                }}
                secondary="614 142 2000"
                secondaryTypographyProps={{
                  className: classes.listItemText,
                  component: 'a',
                  href: 'https://wa.link/ztv7o0',
                }}
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemAvatar>
                <Avatar className={classes.listItemAvatar}>
                  <PhoneInTalkOutlinedIcon className={classes.icon} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Teléfono"
                primaryTypographyProps={{
                  className: `${classes.listItemText} ${classes.listItemHeader}`,
                }}
                secondary="614 434 5353"
                secondaryTypographyProps={{ className: classes.listItemText }}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6}>
          <List>
            <ListItem className={classes.listItem}>
              <ListItemAvatar>
                <Avatar className={classes.listItemAvatar}>
                  <FacebookIcon className={classes.icon} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Facebook"
                primaryTypographyProps={{
                  className: `${classes.listItemText} ${classes.listItemHeader}`,
                }}
                secondary="Imendex"
                secondaryTypographyProps={{
                  className: classes.listItemText,
                  component: 'a',
                  href: 'https://www.facebook.com/imendex',
                }}
              />
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemAvatar>
                <Avatar className={classes.listItemAvatar}>
                  <MailOutlinedIcon className={classes.icon} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Correo electrónico"
                primaryTypographyProps={{
                  className: `${classes.listItemText} ${classes.listItemHeader}`,
                }}
                secondary="ventas.imendex@gmail.com"
                secondaryTypographyProps={{
                  className: classes.listItemText,
                  component: 'a',
                  href: 'mailto:ventas.imendex@gmail.com',
                }}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={10}>
          <ListItem className={classes.listItem}>
            <ListItemAvatar>
              <Avatar className={classes.listItemAvatar}>
                <LocationOnOutlinedIcon className={classes.icon} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Ubicación"
              primaryTypographyProps={{
                className: `${classes.listItemText} ${classes.listItemHeader}`,
              }}
              secondary="Av. Miguel Ahumada No. 164 Col. Industrial. Chihuahua, Chih."
              secondaryTypographyProps={{
                className: classes.listItemText,
                component: 'a',
                href: 'https://goo.gl/maps/K5N1ZQfDbVt1T7RBA',
              }}
            />
          </ListItem>
        </Grid>
      </Grid>
      <Typography id="contacto" variant="h2" className={classes.subheader}>
        Sucursal Ciudad juarez
      </Typography>
      <Grid container className={classes.contact}>
        <Grid item xs={6}>
          <List>
            <ListItem className={classes.listItem}>
              <ListItemAvatar>
                <Avatar className={classes.listItemAvatar}>
                  <LocationOnOutlinedIcon className={classes.icon} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Ubicación"
                primaryTypographyProps={{
                  className: `${classes.listItemText} ${classes.listItemHeader}`,
                }}
                secondary="C. Gildardo Magaña No.427 unidad habitacional E. Zapata  Cd.Juarez, Chih."
                secondaryTypographyProps={{
                  className: classes.listItemText,
                  component: 'a',
                  href: 'https://goo.gl/maps/qajmd2d35fsh9vC58',
                }}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6}>
          <List>
            <ListItem className={classes.listItem}>
              <ListItemAvatar>
                <Avatar className={classes.listItemAvatar}>
                  <PhoneInTalkOutlinedIcon className={classes.icon} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary="Teléfono"
                primaryTypographyProps={{
                  className: `${classes.listItemText} ${classes.listItemHeader}`,
                }}
                secondary="656 626 60 47"
                secondaryTypographyProps={{ className: classes.listItemText }}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
