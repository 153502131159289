import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    paddingLeft: '45px',
    paddingTop: '40px',
    backgroundColor: '#ffffff',
  },

  centerElement: {
    marginTop: '-2.3rem',
  },

  dataField: {
    height: 40,
    width: 400,
    backgroundColor: 'white',
    borderRadius: 6,
    marginRight: '15px',
  },

  logo: {
    width: '220px',
    height: '95px',
  },

  logoContainer: {
    cursor: 'pointer',
    paddingBottom: '159px',
  },

  titleSession: {
    marginBottom: '54px',
    fontSize: '60px',
  },

  titleBold: {
    fontWeight: 'bold',
  },

  textLabel: {
    fontSize: '20px',
    paddingRight: '1rem',
  },

  emailContainer: {
    marginBottom: '30px',
  },
  buttonAqua: {
    textTransform: 'none',
    marginTop: '54px',
    padding: '10px 50px 10px 50px',
    background: '#3ec5bb',
    '&:hover': {
      backgroundColor: '#9be4df',
    },
    color: 'white',
    border: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  viewButton: {
    padding: '10px',
    background: '#3ec5bb',
    '&:hover': {
      backgroundColor: '#9be4df',
    },
    color: 'white',
    border: 'none',
  },

  spaceContainer: {
    paddingTop: '40px',
  },

  link: {
    cursor: 'pointer',
  },
});

export default useStyles;
