import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import useStyles from './NosotrosStyles';

import Tomografo from '../../../assets/images/tomografo1.png'; // TODO: Change image

const Nosotros = () => {
  const classes = useStyles();

  return (
    <Grid id="nosotros" container className={classes.description}>
      <Grid container item direction="column" xs={6}>
        <Typography variant="h2" className={classes.header}>
          Conocimiento integral en <strong>imagenología</strong>
        </Typography>
        <Grid item className={classes.descriptionText}>
          <Typography variant="h5">
            Somos una empresa especializada en imagenología con el personal
            capacitado y la infraestructura necesaria para brindar servicios de
            máxima calidad.
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        style={{ backgroundImage: `url(${Tomografo})` }}
        className={classes.tomografo}
      ></Grid>
    </Grid>
  );
};

export default Nosotros;
