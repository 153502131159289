 import React, { useEffect, useState } from 'react';
import { Grid, Avatar, Button, Typography } from '@material-ui/core';
import useStyles from './SingleClientInfoStyles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { fetchClientById } from '../../../../Utilities/Client/Client';
import Reports from '../../Reports/Reports';
import EditClient from '../EditClient/EditClient';

const ClientInfo = ({ clientId, setIndividualClientView }) => {
  const [client, setClient] = useState();
  const classes = useStyles();

  const [services,setServices] = useState()
  const [isEditing, setIsEditing] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('es-MX', { month: 'long' });
    const year = date.toLocaleString('es-MX', { year: 'numeric' });

    const monthFormatted = month.charAt(0).toUpperCase() + month.slice(1);

    return `${monthFormatted} ${year}`;
  };

  useEffect(async () => {
    const clientData = await fetchClientById(clientId);
    setClient(clientData);
  }, []);

  return client ? (
    isEditing ? (
      <EditClient
        setIsEditing={setIsEditing}
        client={client}
      />
    ) : (
      <Grid container className = {classes.root}>
        <Grid container item direction="row">
          <Grid container item justifyContent="space-between">
            <Grid item xs = {6}>
              <Typography className={classes.clientFullName}>
                Cliente {client.name + ' ' + client.lastName}
              </Typography>
            </Grid>
            <Grid item xs = {6}>
              <Button
                className={classes.cancelButton}
                onClick={() => {
                  setIndividualClientView(false);
                }}
              >
                Regresar
              </Button>
              <Button
                className={classes.buttonAqua}
                onClick={() => {
                  console.log(services)
                  setIsEditing(true);
                }}
              >
                Editar
              </Button>
            </Grid>
          </Grid>
          <Grid item style={{ paddingBottom: '40px' }}>
            <Typography className={classes.titles}>
              Cliente desde {formatDate(client.registerDate)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item>
          <Grid container item xs={9} direction="row">
            <Grid item xs={4}>
              <Avatar
                alt="Mendex"
                src={client.profilePic}
                className={classes.profilePic}
              />
            </Grid>
            <Grid
              container
              item
              direction="column"
              xs={5}
              style={{ paddingLeft: '30px' }}
            >
              <Grid item style={{ paddingBottom: '10px' }}>
                <Typography className={classes.subtitles}>Contacto</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.text}>
                  {client.name + ' ' + client.lastName}
                </Typography>
                <Typography className={classes.text}>{client.phone}</Typography>
                <Typography className={classes.text}>
                  {client.cellPhone}
                </Typography>
                <Typography className={classes.text}>{client.email}</Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.text}>
                  {client.institution}
                </Typography>
                <Typography className={classes.text}>
                  {client.cityinstitution}
                </Typography>
                <Typography className={classes.text}>
                  {client.institutionaddress}
                </Typography>
                <Typography className={classes.text}>{client.rfc}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="column"
              xs={2}
              style={{ paddingTop: '10px', paddingLeft: '70px' }}
            >
              <Grid item style={{ paddingBottom: '15px' }}>
                <Typography className={classes.subtitles}>Servicios</Typography>
              </Grid>
              <Grid item>
                {client.services.map((service) =>
                  service !== 'rayosx' ? (
                    <Typography className={classes.text}>
                      {service.charAt(0).toUpperCase() + service.slice(1)}
                    </Typography>
                  ) : (
                    <Typography className={classes.text}>Rayos-X</Typography>
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Reports userData={client} clients={true} />
      </Grid>
    )
  ) : (
    <LinearProgress color="secondary" />
  );
};

export default ClientInfo;
