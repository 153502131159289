import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: 'rgba(155, 228, 223, 0.2)',
    padding: '1.875rem 1.5rem',
  },
  avatar: {
    width: '4rem',
    height: '4rem',
  },
  messageStyle: {
    float: 'left'
  },
  messageDate: {
    marginRight: '.6rem',
    float: 'right',
    fontSize: 10,
    color: '#707579'
  },
  messageHour: {
    float: 'right',
    fontSize: 10,
    color: '#707579'
  },
  institution: { 
    fontSize: 30,
    fontWeight: 'bold',
    opacity: '60%',
    paddingLeft: '1rem'
  },
  iconButton: {
    width: '40px',
    height: '40px',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
  },
  messagesContainer: {
    padding: '1.625rem',
    minHeight: '62vh',
    border: '1px solid #e5e5e5',
  },
  individualMessage: {
    marginBottom: '2rem',
  },
  messageAvatar: {
    width: '27px',
    height: '27px',
    marginRight: '1rem',
  },
  inputBarContainer: {
    height: '3rem',
  },
  inputBar: {
    width: '90%',
  },
  input: {
    height: '3rem',
    borderRadius: '0.625rem',
  },
  sendButton: {
    width: '15%',
    height: '3rem',
    textTransform: 'none',
    color: 'white',
    fontWeight: 'bold',
  },
}));

export default useStyles;
