import React, { useState, useEffect } from 'react';
import {
  Grid,
  Tab,
  Tabs,
  Avatar,
  Typography,
  Divider,
  IconButton,
  Hidden,
} from '@material-ui/core';
import {
  Switch,
  Route,
  Link,
  useRouteMatch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import useStyles from './DashboardStyles';

// Logos
import mendexLogo from '../../assets/icons/LOGO_MENDEX.svg';
import messageN from '../../assets/icons/BandejaEP.svg';
import message from '../../assets/icons/Bandejaentrada.svg';
import notificationP from '../../assets/icons/NotificationP.svg';
import notification from '../../assets/icons/Notification.svg';

import RepoIcon from '../../assets/icons/Reportes.svg';
import ContactIcon from '../../assets/icons/Contacto.svg';
import CerrarSesion from '../../assets/icons/CerrarSesion_.svg';

import Reports from './Reports/Reports';

import Clients from './Client/Client';

import Contact from './Contact/Contact';
import Users from './Users/Users';
import Messages from './Messages/Messages';
import Notifications from './Notifications/Notifications';

import { useAuth } from '../../Contexts/AuthContext';

const TabPanel = (props) => {
  const { children, value, url, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={url.search(value) == -1}
      id={`vertical-tabpanel-${url}`}
      aria-labelledby={`vertical-tab-${url}`}
      {...other}
    >
      {url.search(value) !== -1 && (
        <Grid>
          <Grid item xs={12} container>
            {children}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const Dashboard = () => {
  const classes = useStyles();

  const { logout, currentUser, getUserData } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();

  const [value, setValue] = useState(0);
  const [empty, setEmpty] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  const [data, setData] = useState({
    adminUser: false,
    notifyEmail: 'desactivado',
    profilePic: '',
    registerDate: '',
    email: '',
    name: '',
    lastName: '',
    cellPhone: 0,
    phone: 0,
    institution: '',
    instituteAddress: '',
    CPInstitution: 0,
    cityInstitution: '',
    billingName: '',
    RFC: '',
    billingAddress: '',
    CPBilling: 0,
    cityBilling: '',
    emailBilling: '',
    services: {},
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeUser = () => {
    setValue('/dashboard/perfil');
  };

  const handleNavigateToMessages = () => {
    setValue('/dashboard/mensajes');
    history.push('/dashboard/mensajes');
  };

  const handleNavigateToNotifications = () => {
    setValue('/dashboard/notificaciones');
    history.push('/dashboard/notificaciones');
  };

  const logoutHandler = async () => {
    try {
      await logout().then;
      history.push('/login');
    } catch {
      setNotify({
        isOpen: true,
        message: 'Error al tratar de cerrar sesión',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    getUserData(currentUser.uid).then((userData) => {
      setData({ id: currentUser.uid, ...userData });
    });
  }, [currentUser.uid]);

  useEffect(() => {
    if (location.pathname != '/dashboard/clientes') {
      if (location.pathname !== '/dashboard/reportes') {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
    } else {
      setEmpty(false);
    }
  }, [location]);

  return (
    <div>
      <Grid container item xs={12} className={classes.appbar}>
        <Grid item xs={2} className={classes.logo}>
          <img src={mendexLogo} className={classes.logoMendex}></img>
        </Grid>
        <Grid item xs={4} />
        <Grid container item className={classes.barContainer}>
          <Grid
            container
            item
            justifyContent="flex-end"
            alignItems="center"
            xs={5}
            style={{ textAlign: 'center' }}
          >
            {data.adminUser ? (
              <IconButton
                onClick={handleNavigateToMessages}
                className={
                  value === `${path}/mensajes`
                    ? classes.buttonSelected
                    : classes.button
                }
              >
                {false ?  (<img src={messageN}></img>):(<img src={message}></img>)}
              </IconButton>
            ) : null}
            <IconButton
              onClick={handleNavigateToNotifications}
              className={
                value === `${path}/notificaciones`
                  ? classes.buttonSelected
                  : classes.button
              }
            >
              {isOpen ?  (<img src={notificationP}></img>):(<img src={notification}></img>)}          
            </IconButton>
          </Grid>
          <Grid container item xs={1} direction="column">
            <Divider orientation="vertical" className={classes.divider} />
          </Grid>
          <Grid
            container
            item
            xs={2}
            direction="column"
            onClick={handleChangeUser}
          >
            <Link to={`${path}/perfil`}>
              <Avatar
                alt="img"
                src={data.profilePic}
                className={
                  value === `${path}/perfil`
                    ? classes.userSelected
                    : classes.perfilPic
                }
              />
            </Link>
          </Grid>
          <Hidden mdDown>
            <Grid
              container
              item
              xs={4}
              direction="column"
              className={classes.name}
              onClick={handleChangeUser}
            >
              <Link to={`${path}/perfil`} className={classes.username}>
                <Typography variant="h6">
                  {data.name} {data.lastName}
                </Typography>
              </Link>
            </Grid>
          </Hidden>
        </Grid>
        <Grid item xs={1} />
      </Grid>
      <Grid container item xs={10} className={classes.root}>
        <Grid container item xs={2}>
          <Tabs
            orientation="vertical"
            value={location.pathname}
            onChange={handleChange}
            className={classes.tabs}
            TabIndicatorProps={
              empty
                ? { className: classes.emptyindicator }
                : { className: classes.indicator }
            }
          >
            <Tab
              value={`${path}/reportes`}
              component={Link}
              to={`${path}/reportes`}
              className={
                value === `${path}/reportes` ? classes.tabSelected : classes.tab
              }
              label={
                <Typography
                  className={
                    value === `${path}/reportes`
                      ? classes.selected
                      : classes.normal
                  }
                  variant="h6"
                >
                  <img
                    src={RepoIcon}
                    alt="repoIcon"
                    style={{ verticalAlign: 'middle', marginRight: '1.8rem' }}
                  />
                  Reportes
                </Typography>
              }
            />
            <Tab
              value={data.adminUser ? `${path}/clientes` : `${path}/contacto`}
              component={Link}
              to={data.adminUser ? `${path}/clientes` : `${path}/contacto`}
              className={
                value === (`${path}/clientes` || `${path}/contacto`)
                  ? classes.tabSelected
                  : classes.tab
              }
              label={
                <Typography
                  className={
                    value === (`${path}/clientes` || `${path}/contacto`)
                      ? classes.selected
                      : classes.normal
                  }
                  variant="h6"
                >
                  <img
                    src={ContactIcon}
                    alt="ContactIcon"
                    style={{ verticalAlign: 'middle', marginRight: '1.8rem' }}
                  />
                  {data.adminUser ? 'Clientes' : 'Contacto'}
                </Typography>
              }
            />
            <Grid
              onClick={logoutHandler}
              container
              item
              className={classes.logOutButton}
            >
              <Grid item>
                <img
                  src={CerrarSesion}
                  alt="ContactIcon"
                  style={{ verticalAlign: 'middle', marginRight: '1rem' }}
                />
              </Grid>
              <Grid item>
                <Typography style={{ fontSize: '20px' }}>
                  Cerrar sesión
                </Typography>
              </Grid>
            </Grid>
          </Tabs>
        </Grid>
        <Grid container item xs={10}>
          <Switch>
            <Route path={`${path}/reportes`}>
              <TabPanel
                value={`${path}/reportes`}
                url={location.pathname}
                className={classes.content}
              >
                <Reports userData={data} clients={false} />
              </TabPanel>
            </Route>
            <Route
              path={data.adminUser ? `${path}/clientes` : `${path}/contacto`}
            >
              <TabPanel
                value={data.adminUser ? `${path}/clientes` : `${path}/contacto`}
                url={location.pathname}
                className={classes.content}
              >
                {data.adminUser ? <Clients userData={data} /> : <Contact />}
              </TabPanel>
            </Route>
            <Route path={`${path}/perfil`}>
              <TabPanel
                value={`${path}/perfil`}
                url={location.pathname}
                className={classes.content}
              >
                <Users data={data} setUserData={setData} />
              </TabPanel>
            </Route>
            <Route path={`${path}/mensajes`}>
              <TabPanel
                value={`${path}/mensajes`}
                url={location.pathname}
                className={classes.content}
              >
                <Messages data={data} setUserData={setData}/>
              </TabPanel>
            </Route>
            <Route path={`${path}/notificaciones`}>
              <TabPanel
                value={`${path}/notificaciones`}
                url={location.pathname}
                className={classes.content}
              >
                <Notifications userData={data} setIsOpen={setIsOpen} isOpen={isOpen}/>
              </TabPanel>
            </Route>
          </Switch>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
