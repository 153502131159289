import React, { useEffect, useState, useRef } from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import useStyles from './ClientStyles';
import SearchBar from '../../../Shared/SearchBar/SearchBar';
import Table from '../../../Shared/Table/Table';
import CreateClient from './CreateClient/CreateClient';
import CreateMendex from './CreateMendex/CreateMendex';
import ClientInfo from './SingleClientInfo/SingleClientInfo';
import { listenUsersCollection } from '../../../Utilities/Client/Client';
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useLocation,
} from 'react-router-dom';

const tableHeaderCells = [
  { id: 'client', disablePadding: true, label: 'Cliente' },
  { id: 'institution', disablePadding: false, label: 'Institución' },
  { id: 'phone', disablePadding: false, label: 'Teléfono' },
  { id: 'email', disablePadding: false, label: 'Correo Electronico' },
];

const Clients = ({ userData }) => {
  const classes = useStyles();
  const location = useLocation();
  const { path } = useRouteMatch();
  const history = useHistory();

  const [clientsArray, setClientsArray] = useState([]);
  const [filteredClients, setFilteredClient] = useState([]);
  const [individualClientView, setIndividualClientView] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [createUser, setCreateUser] = useState(false);
  const [orderBy, setOrderBy] = useState('Por cliente');
  const [searchTerm, setSearchTerm] = useState("")

  const activeClientView = useRef('');
  const mounted = useRef(false);

  const handleCellClick = (client) => {
    history.push(`${path}?c=${client.id}`);
  };

  const handleCreateUser = (isMendex) => {
    if (isMendex) {
      setCreateUser(true);
      history.push(`${path}/addAdmin`);
    } else {
      setCreateUser(true);
      history.push(`${path}/addClient`);
    }
  };

  const handleUpdateSearchTerm = (newSearchTerm) =>{
    console.log(newSearchTerm)
    setSearchTerm(newSearchTerm);
  }

  const handleFilterItems = () => {
    setFilteredClient((prevFilteredClient) =>
      prevFilteredClient.filter((client) => {
        return Object.keys(client).some((property) => {
          console.log(property)
          // Only check if these properties match the searchTerm
          if (
            property === 'Name' ||
            property === 'LastName' ||
            property === 'Institution' ||
            property === 'Email' ||
            property === 'Phone'
          ) {
            if (client[property] && typeof client[property] === 'string') {
              return client[property]
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
            }
          }
          return false;
        });
      })
    );
  };

  useEffect(() => {
    if (!searchTerm) {
      setFilteredClient(clientsArray);
    } else {
      handleFilterItems();
    }
  }, [searchTerm]);


  useEffect(() => {
    const query = new URLSearchParams(location.search).get('c');
    if (query) {
      activeClientView.current = query;
      setIndividualClientView(true);
    } else {
      setIndividualClientView(false);
    }
  }, [location]);

  useEffect(() => {
    let unsubscribe;
    if (!mounted.current) {
      unsubscribe = listenUsersCollection((snapshot) => {
        const changes = snapshot.docChanges();
        changes.map((change) => {
          if (change.type === 'added') {
            const client = { id: change.doc.id, ...change.doc.data() };
            if (client.AdminUser === false) {
              setClientsArray((prevClient) => {
                const clientesArray = [...prevClient];
                clientesArray.push(client);

                // Since searchTerm is empty, set filtered clients = initial clients
                setFilteredClient(clientesArray);
                return clientesArray;
              });
            }
          }
          if (change.type === 'removed') {
            setFilteredClient((prevFilteredDocs) =>
              prevFilteredDocs.filter((doc) => doc.id !== change.doc.id)
            );
            setClientsArray((prevDocs) =>
              prevDocs.filter((doc) => doc.id !== change.doc.id)
            );
          }
        });
      });
    }
    return unsubscribe;
  }, []);

  return (
    <Grid container direction="column">
      {individualClientView ? (
        <ClientInfo
          clientId={activeClientView.current}
          setIndividualClientView={setIndividualClientView}
        />
      ) : null}
      {createUser || individualClientView ? null : (
        <>
          <Grid>
            <SearchBar label={'Buscar Cliente'} updateSearchTerm={handleUpdateSearchTerm} />
            <Typography variant="h2" className={classes.header}>
              <strong>Clientes</strong>
            </Typography>
            <Grid
              container
              justifyContent="flex-end"
              style={{ paddingBottom: '20px' }}
            >
              <Button
                disableElevation
                variant="contained"
                size="medium"
                className={classes.button}
                onClick={() => {
                  handleCreateUser(false);
                }}
              >
                <Typography>
                  <strong>+ Nuevo Cliente</strong>
                </Typography>
              </Button>
              <Button
                disableElevation
                variant="contained"
                size="medium"
                className={classes.button}
                onClick={() => {
                  handleCreateUser(true);
                }}
              >
                <Typography>
                  <strong>+ Nuevo Mendex</strong>
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Table
            headerCells={tableHeaderCells}
            info={filteredClients}
            defaultOrderBy={orderBy.id}
            handleCellClick={handleCellClick}
            userData={userData}
            setIsEditing={setIsEditing}
            setIsDocActive={setIndividualClientView}
            tableType={'clientes'}
          />
        </>
      )}
      <Switch>
        <Route path={`${path}/addAdmin`}>
          <CreateMendex setCreateUser={setCreateUser} />
        </Route>
        <Route path={`${path}/addClient`}>
          <CreateClient setCreateUser={setCreateUser} />
        </Route>
      </Switch>
    </Grid>
  );
};

export default Clients;
