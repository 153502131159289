import './App.css';
import { BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import { AuthProvider } from './Contexts/AuthContext';

//components
import LandingPage from './components/LandingPage/Landing';
import Login from './components/Auth/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import ProtectedRoute from './Helpers/ProtectedRoute';

const App = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Switch>
          <Route path="/" component={LandingPage} exact />
          <Route path='/login'  component={Login} exact/>
          <ProtectedRoute path="/dashboard" component={Dashboard} />
        </Switch>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
