import React from 'react';
import { Grid, TextField, Typography, Button } from '@material-ui/core';
import useStyles from './SearchBarStyles';

const SearchBar = ({ updateSearchTerm, label }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.topContainer}
    >
      <Grid item xs={8} className={classes.searchInputContainer}>
        <TextField
          label={label}
          variant="outlined"
          size="small"
          onChange={(e) => updateSearchTerm(e.target.value)}
          className={classes.searchInput}
        />
      </Grid>
      <Grid>
        <Button
          variant="contained"
          disableElevation
          className={classes.searchButton}
        >
          <Typography>Buscar</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default SearchBar;
