import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    cursor: 'pointer',
  },

  table: {
    minWidth: 750,
    borderCollapse: 'separate',
    borderSpacing: '0 12px',
  },
  checkbox: {
    color: theme.palette.primary.main,
  },
  tableHeaderCell: {
    border: '0px solid transparent',
    padding: 0,
    fontSize: '1rem',
    fontWeight: 1000,
  },
  tableHeaderCellClient: {
    border: '0px solid transparent',
    padding: 10,
    fontSize: '1rem',
    fontWeight: 1000,
  },
  tableCell: {
    fontSize: '1rem',
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    padding: 0,
    cursor: 'pointer',
  },
  tableCellClients: {
    fontSize: '1rem',
    borderTop: `1px solid ${theme.palette.primary.main}`,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    paddingTop: "10px",
    padding: "10px",
    cursor: 'pointer',
  },
  leftTableCell: {
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    borderLeft: `1px solid ${theme.palette.primary.main}`,
  },
  rightTableCell: {
    width: '4rem',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    borderRight: `1px solid ${theme.palette.primary.main}`,
  },

}));

export default useStyles;
