import { makeStyles, Icon } from '@material-ui/core';

const useStyles = makeStyles({
  header: {
    fontSize: '3.25rem',
    margin: '50px 0 30px 60px',
    padding: '20px 0 20px 40px',
    color: 'white',
    backgroundColor: '#3ec5bb',
    width: '100%',
  },
  subheader: {
    fontSize: '2rem',
    marginLeft: '50px',
    paddingLeft: '35px',
    width: '100%',
  },
  contact: {
    marginTop: '40px',
    marginBottom: '80px',
    textAlign: 'center',
  },
  listItem: {
    paddingLeft: '150px',
  },
  listItemText: {
    fontSize: '1.25rem',
  },
  listItemHeader: {
    fontWeight: '1000',
  },
  listItemAvatar: {
    width: '64px',
    height: '64px',
    margin: '20px',
    backgroundColor: 'white',
    boxShadow: '3px 3px 10px lightgrey',
  },
  icon: {
    width: '34px',
    height: '34px',
    color: 'black',
  },
});

export default useStyles;
