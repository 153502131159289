import React from 'react';
import { Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import useStyles from './LandingStyles';

// Components
import Navbar from './Navbar/Navbar';
import Nosotros from './Nosotros/Nosotros';
import Servicios from './Servicios/Servicios';
import Footer from './Footer/Footer';
import {auth} from '../../Utilities/Firebase';
import { useLocation, useHistory } from 'react-router-dom';
import {useAuthState} from 'react-firebase-hooks/auth'

const LandingPage = () => {
  const classes = useStyles();

  const history = useHistory();

  const [user] = useAuthState(auth);

  const location = useLocation();


  if(user && location.pathname == '/'){
    history.replace('/dashboard/reportes')
  }

  return (
    <Grid container>
      <Navbar />
      <Nosotros />
      <Divider variant="middle" className={classes.divider} />
      <Servicios />
      <Divider variant="middle" className={classes.divider} />
      <Footer />
    </Grid>
  );
};

export default LandingPage;
