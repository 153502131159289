import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyD86Zl0_cI4vID_J4csZt7dafaCVcdB2vE',
  authDomain: 'mendex-4a355.firebaseapp.com',
  projectId: 'mendex-4a355',
  storageBucket: 'mendex-4a355.appspot.com',
  messagingSenderId: '337012309618',
  appId: '1:337012309618:web:8470da9cc6e0731550cceb',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

export const db = firebase.firestore();

export const storage = firebase.storage();

export const firebaseErrorCodes = new Map();
firebaseErrorCodes.set(
  'auth/email-already-exists',
  'El correo no está disponible.'
);
firebaseErrorCodes.set(
  'auth/invalid-email',
  'El correo proporcionado no es válido.'
);
firebaseErrorCodes.set(
  'auth/weak-password',
  'La contraseña proporcionada no es válida.'
);
firebaseErrorCodes.set(
  'auth/invalid-password',
  'La contraseña proporcionada no es válida.'
);
firebaseErrorCodes.set(
  'auth/wrong-password',
  'Las credenciales no son correctas.'
);

export default firebase;
