import React, { useState } from 'react';
import { Grid, Button, Typography, Avatar, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useStyles from './CreateMendexStyles';
import PublishIcon from '@material-ui/icons/Publish';
import { useAuth } from '../../../../Contexts/AuthContext';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { DropzoneDialog } from 'material-ui-dropzone';
import Notification from '../../../../Helpers/Notification';
import { storage } from '../../../../Utilities/Firebase';
import { regexMail, onlyChar, onlyNum } from '../../../../Helpers/Regex';

const CreateMendex = ({ setCreateUser }) => {
  const classes = useStyles();

  const { signUpMendex } = useAuth();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState('password');
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  const [data, setData] = useState({
    adminUser: true,
    institution: '',
    email: '',
    name: '',
    lastName: '',
    cellPhone: '',
    phone: '',
    password: '',
    profilePic: '',
  });

  const correctData = {
    institution: true,
    email: true,
    name: true,
    lastName: true,
    cellPhone: true,
    phone: true,
    password: true,
  };

  const handleChange = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onClickverifyData = (data) => {
    if (!data.password) {
      setNotify({
        isOpen: true,
        message: 'Campo de contraseña no puede estar vacío',
        type: 'error',
      });
      correctData.password = false;
    } else if (data.password.length < 6) {
      setNotify({
        isOpen: true,
        message: 'La contraseña debe de ser mayor a 6 caracteres',
        type: 'error',
      });
      correctData.password = false;
    } else {
      correctData.password = true;
    }

    if (!data.email) {
      setNotify({
        isOpen: true,
        message: 'Campo de correo no puede estar vacío',
        type: 'error',
      });
      correctData.email = false;
    } else if (!regexMail.test(data.email)) {
      setNotify({
        isOpen: true,
        message: 'El correo tiene un formato no permitido',
        type: 'error',
      });
      correctData.email = false;
    } else {
      correctData.email = true;
    }

    if (!data.name) {
      setNotify({
        isOpen: true,
        message: 'Campo de nombre no puede estar vacío',
        type: 'error',
      });
      correctData.name = false;
    } else if (!onlyChar.test(data.name)) {
      setNotify({
        isOpen: true,
        message: 'Solo se pueden colocar letras en el nombre',
        type: 'error',
      });
      correctData.name = false;
    } else {
      correctData.name = true;
    }

    if (!data.lastName) {
      setNotify({
        isOpen: true,
        message: 'Campo de apellido no puede estar vacío',
        type: 'error',
      });
      correctData.lastName = false;
    } else if (!onlyChar.test(data.lastName)) {
      setNotify({
        isOpen: true,
        message: 'Solo se pueden colocar letras en el apellido',
        type: 'error',
      });
      correctData.lastName = false;
    } else {
      correctData.lastName = true;
    }

    if (!data.phone) {
      setNotify({
        isOpen: true,
        message: 'Campo de telefono no puede estar vacío',
        type: 'error',
      });
      correctData.phone = false;
    } else if (!onlyNum.test(data.phone)) {
      setNotify({
        isOpen: true,
        message: 'Solo pueden colocar numeros en el telefono',
        type: 'error',
      });
      correctData.phone = false;
    } else {
      correctData.phone = true;
    }

    if (!data.cellPhone) {
      setNotify({
        isOpen: true,
        message: 'Campo de celular no puede estar vacío',
        type: 'error',
      });
      correctData.cellPhone = false;
    } else if (!onlyNum.test(data.cellPhone)) {
      setNotify({
        isOpen: true,
        message: 'Solo pueden colocar numeros en el celular',
        type: 'error',
      });
      correctData.cellPhone = false;
    } else {
      correctData.cellPhone = true;
    }

    if (
      correctData.email &&
      correctData.name &&
      correctData.lastName &&
      correctData.institution &&
      correctData.cellPhone &&
      correctData.phone
    ) {
      signUpMendex(data, setCreateUser, history);
    }
  };

  const uploadHandler = async (files) => {
    const image = files[0];

    const uploadRef = storage.ref().child(`AvatarImage/${image.name}`);

    await uploadRef
      .put(image)
      .then(() => {
        setNotify({
          isOpen: true,
          message: 'La imagen se subio correctamente ',
          type: 'success',
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            'Hubo un problema a al tratar de subir la imagen, intentalo mas tarde ',
          type: 'error',
        });
        console.error(error);
      });

    await uploadRef.getDownloadURL().then((url) => {
      setData({ ...data, profilePic: url });
    });
  };

  return (
    <Grid container item className={classes.root}>
      <Grid container item direction="row" xs={12}>
        <Grid item xs={6}>
          <Typography className={classes.createTitle}>Mendex nuevo</Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.buttonAqua}
            onClick={() => {
              onClickverifyData(data);
            }}
          >
            Crear Mendex
          </Button>
          <Button
            className={classes.cancelButton}
            onClick={() => {
              setCreateUser(false);
              history.push('/dashboard/clientes');
            }}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
      <Grid item style={{ paddingBottom: '20px' }}>
        <Typography className={classes.title}>Información</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item direction="column" xs={3}>
          <Grid container>
            <Avatar className={classes.avatar} src={data.profilePic}>
              <PublishIcon className={classes.iconSize} />
            </Avatar>
          </Grid>
          <Grid container>
            <Button
              variant="contained"
              component="label"
              className={classes.buttonImg}
              onClick={() => setOpen(true)}
            >
              Subir foto
            </Button>
            <DropzoneDialog
              acceptedFiles={['image/*']}
              cancelButtonText={'cancel'}
              submitButtonText={'submit'}
              maxFileSize={5000000}
              open={open}
              onClose={() => setOpen(false)}
              onSave={(files) => {
                uploadHandler(files);
                setOpen(false);
              }}
              showPreviews={true}
              showFileNamesInPreview={true}
            />
          </Grid>
        </Grid>
        <Grid container item direction="column" xs={4}>
          <Grid item>
            <Typography className={classes.subtitle}>
              Correo electronico
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              required
              InputProps={{
                className: classes.dataField,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className={classes.dataField}
              value={data.email}
              type="email"
              name="email"
              onChange={handleChange}
            ></TextField>
          </Grid>
          <Grid item>
            <Typography className={classes.subtitle}>Contraseña</Typography>
          </Grid>
          <Grid item>
            <TextField
              required
              InputProps={{
                className: classes.dataField,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              className={classes.dataField}
              value={data.password}
              type={showPassword}
              name="password"
              onChange={handleChange}
            ></TextField>
            <Button
              className={classes.viewButton}
              onClick={() => {
                showPassword === 'password'
                  ? setShowPassword('text')
                  : setShowPassword('password');
              }}
            >
              {showPassword === 'password' ? (
                <VisibilityOffIcon />
              ) : (
                <VisibilityIcon />
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="row"
        xs={12}
        className={classes.container}
      >
        <Grid container item direction="column" xs={6}>
          <Grid item>
            <Typography className={classes.subtitle}>
              Informacion personal
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction row>
              <Grid item irection="column">
                <Typography className={classes.text}>Nombre</Typography>
                <Typography className={classes.text}>Apellidos</Typography>
              </Grid>
              <Grid item direction="column">
                <Grid>
                  <TextField
                    required
                    type="text"
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className={classes.dataField}
                    value={data.name}
                    name="name"
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid>
                  <TextField
                    required
                    type="text"
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    className={classes.dataField}
                    value={data.lastName}
                    name="lastName"
                    onChange={handleChange}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item direction="column" xs={6} className = {classes.contacto}>
          <Grid item>
            <Typography className={classes.subtitle}>
              Informacion de Contacto
            </Typography>
          </Grid>
          <Grid container direction row>
            <Grid item irection="column">
              <Typography className={classes.text}>Telefono</Typography>
              <Typography className={classes.text}>Celular</Typography>
            </Grid>
            <Grid item direction="column">
              <Grid>
                <TextField
                  required
                  type="number"
                  InputProps={{
                    className: classes.dataField,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  className={classes.dataField}
                  value={data.phone}
                  name="phone"
                  onChange={handleChange}
                ></TextField>
              </Grid>
              <Grid>
                <TextField
                  required
                  type="number"
                  InputProps={{
                    className: classes.dataField,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  className={classes.dataField}
                  value={data.cellPhone}
                  name="cellPhone"
                  onChange={handleChange}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} position={'bottom'} />
    </Grid>
  );
};

export default CreateMendex;
