import React, { createContext, useContext, useEffect, useState } from 'react';
import { Fragment } from 'react';
import firebase, { auth, db, firebaseErrorCodes } from '../Utilities/Firebase';
import Notification from '../Helpers/Notification';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [pending, setPending] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const userDataRef = db.collection('UserData');

  const logOutUser = () => {
    return {
      type: 'LOGOUT_USER',
    };
  };

  const registerDate = () => {
    const hoy = new Date().toString();
    return hoy;
  };

  const signUpClient = (data, services,servicesBool, setCreateUser, history) => {
    auth
      .createUserWithEmailAndPassword(data.email, data.password)
      .then((cred) => {
        userDataRef
          .doc(cred.user.uid)
          .set({
            AdminUser: data.adminUser,
            NotifyEmail: 'desactivado',
            ProfilePic: '',
            RegisterDate: registerDate(),
            Email: data.email,
            Name: data.name,
            LastName: data.lastName,
            CellPhone: data.cellPhone,
            Phone: data.phone,
            Institution: data.institution,
            InstitutionAddress: data.address,
            Services: services,
            ServicesBool: servicesBool,
            CPInstitution: '',
            CityInstitution: '',
            BillingName: '',
            RFC: '',
            BillingAddress: '',
            CPBilling: '',
            CityBilling: '',
            EmailBilling: '',
          })
          .then(() => {
            setNotify({
              isOpen: true,
              message: 'Se creo el Cliente correctamente',
              type: 'success',
            });
            setCreateUser(false);
            history.push('/dashboard');
          })
          .catch((error) => {
            console.error(error);
            setNotify({
              isOpen: true,
              message: error.message,
              type: 'error',
            });
            setCreateUser(false);
            history.push('/dashboard/clientes');
          });
      })
      .catch((error) => {
        console.error(error);
        setNotify({
          isOpen: true,
          message: error.message,
          type: 'error',
        });
      });
  };

  const signUpMendex = (data, setCreateUser, history) => {
    auth
      .createUserWithEmailAndPassword(data.email, data.password)
      .then((cred) => {
        userDataRef
          .doc(cred.user.uid)
          .set({
            AdminUser: data.adminUser,
            NotifyEmail: 'desactivado',
            ProfilePic: data.profilePic,
            RegisterDate: registerDate(),
            Institution: data.institution,
            Email: data.email,
            Name: data.name,
            LastName: data.lastName,
            CellPhone: data.cellPhone,
            Phone: data.phone,
            isChatting: true,
          })
          .then(() => {
            setNotify({
              isOpen: true,
              message: 'Se creo el Mendex correctamente',
              type: 'success',
            });
            setCreateUser(false);
            history.push('/dashboard/clientes');
          })
          .catch((error) => {
            console.error(error);
            setNotify({
              isOpen: true,
              message: error.message,
              type: 'error',
            });
            setCreateUser(false);
            history.push('/dashboard/clientes');
          });
      })
      .catch((error) => {
        console.error(error);
        setNotify({
          isOpen: true,
          message: firebaseErrorCodes.get(error.code),
          type: 'error',
        });
      });
  };

  const login = (data) => {
    return auth
      .signInWithEmailAndPassword(data.email, data.password)
      .then((userCredential) => {
        setCurrentUser(userCredential.user);
        auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        setNotify({
          isOpen: true,
          message: 'Se inició sesión correctamente',
          type: 'success',
        });
      })
      .catch((error) => {
        console.error(error);
        setNotify({
          isOpen: true,
          message: firebaseErrorCodes.get(error.code),
          type: 'error',
        });
      });
  };

  const logout = () => {
    return auth
      .signOut()
      .then(() => {
        setCurrentUser(null);
        setNotify({
          isOpen: true,
          message: 'Sesión terminada correctamente',
          type: 'success',
        });
        logOutUser();
      })
      .catch(() => {
        setNotify({
          isOpen: true,
          message: 'Error al momento de cerrar sesión intentalo mas tarde',
          type: 'error',
        });
      });
  };

  const saveNewClientData = (newData, currentUser) => {
    firebase
      .firestore()
      .collection('UserData')
      .doc(currentUser.uid)
      .update({
        ProfilePic: newData.profilePic,
        Email: newData.email,
        Name: newData.name,
        LastName: newData.lastName,
        CellPhone: newData.cellPhone,
        Phone: newData.phone,
        Institution: newData.institution,
        InstitutionAddress: newData.instituteAddress,
        CPInstitution: newData.CPInstitution,
        CityInstitution: newData.cityInstitution,
        BillingName: newData.billingName,
        RFC: newData.RFC,
        BillingAddress: newData.billingAddress,
        CPBilling: newData.CPBilling,
        CityBilling: newData.cityBilling,
        EmailBilling: newData.emailBilling,
      })
      .then(() => {
        setNotify({
          isOpen: true,
          message: 'Informacion actualizada correctamente',
          type: 'success',
        });
      })
      .catch(() => {
        setNotify({
          isOpen: true,
          message:
            'Hubo un error al momento de actualizar los datos, intentalo mas tarde',
          type: 'error',
        });
      });
  };

  const saveNewMendexData = (newData) => {
    firebase
      .firestore()
      .collection('UserData')
      .doc(currentUser.uid)
      .update({
        NotifyEmail: newData.notifyEmail,
        ProfilePic: newData.profilePic,
        Email: newData.email,
        Name: newData.name,
        LastName: newData.lastName,
        CellPhone: newData.cellPhone,
        Phone: newData.phone,
      })
      .then(() => {
        setNotify({
          isOpen: true,
          message: 'Informacion actualizada correctamente',
          type: 'success',
        });
      })
      .catch(() => {
        setNotify({
          isOpen: true,
          message:
            'Hubo un error al momento de actualizar los datos, intentalo mas tarde',
          type: 'error',
        });
      });
  };

  const updateNotifications = (notify) => {
    firebase
      .firestore()
      .collection('UserData')
      .doc(currentUser.uid)
      .update({
        NotifyEmail: notify,
      })
      .then(() => {
        if (notify === 'desactivado') {
          setNotify({
            isOpen: true,
            message: 'Notificaciones desactivadas',
            type: 'success',
          });
        } else if (notify === 'activado') {
          setNotify({
            isOpen: true,
            message: 'Notificaciones activadas',
            type: 'success',
          });
        }
      })
      .catch(() => {
        setNotify({
          isOpen: true,
          message:
            'Hubo un error al momento de actualizar los datos, intentalo mas tarde',
          type: 'error',
        });
      });
  };

  const getUserData = (uid) => {
    return new Promise((resolve, reject) => {
      let data;
      userDataRef
        .doc(uid)
        .get()
        .then((doc) => {
          data = {
            adminUser: doc.data().AdminUser,
            profilePic: doc.data().ProfilePic,
            notifyEmail: doc.data().NotifyEmail,
            registerDate: doc.data().RegisterDate,
            name: doc.data().Name,
            lastName: doc.data().LastName,
            email: doc.data().Email,
            cellPhone: doc.data().CellPhone,
            phone: doc.data().Phone,
            institution: doc.data().Institution,
            instituteAddress: doc.data().InstitutionAddress,
            CPInstitution: doc.data().CPInstitution,
            cityInstitution: doc.data().CityInstitution,
            billingName: doc.data().BillingName,
            RFC: doc.data().RFC,
            billingAddress: doc.data().BillingAddress,
            CPBilling: doc.data().CPBilling,
            cityBilling: doc.data().CityBilling,
            emailBilling: doc.data().EmailBilling,
          };

          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const fetchClients = async () => {
    let clients = [];
    const userDataCollection = await userDataRef.get();
    userDataCollection.forEach((user) => {
      if (!user.data().AdminUser) {
        clients.push({ id: user.id, ...user.data() });
      }
    });

    return clients;
  };

  const resetPassword = (email) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setNotify({
          isOpen: true,
          message: 'Revise su correo electronico para restaurar su contraseña ',
          type: 'success',
        });
      })
      .catch(() => {
        setNotify({
          isOpen: true,
          message:
            'Hubo un error al cambiar la contraseña, intentalo mas tarde',
          type: 'error',
        });
      });
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setPending(false);
    });
  }, []);

  if (pending) {
    return <></>;
  }

  const value = {
    currentUser,
    signUpClient,
    signUpMendex,
    login,
    logout,
    saveNewClientData,
    saveNewMendexData,
    updateNotifications,
    getUserData,
    resetPassword,
    fetchClients,
  };

  return (
    <Fragment>
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
      <Notification notify={notify} setNotify={setNotify} position={'top'} />
    </Fragment>
  );
};
