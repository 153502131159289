import React, { useState } from 'react';
import {
  Button,
  Grid,
  Typography,
  Avatar,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  TextField,
} from '@material-ui/core';
import useStyles from './UsersStyles';
import { useAuth } from '../../../Contexts/AuthContext';
import { regexMail, onlyChar, alphaNum } from '../../../Helpers/Regex';
import Notification from '../../../Helpers/Notification';
import firebase, { storage } from '../../../Utilities/Firebase';
import { DropzoneDialog } from 'material-ui-dropzone';

const Users = (props) => {
  const classes = useStyles();
  const { data, setUserData } = props;
  const {
    currentUser,
    saveNewClientData,
    saveNewMendexData,
    updateNotifications,
  } = useAuth();

  const [passwords, setPasswords] = useState({
    allPasswords: false,
    newPassword: '',
    confirmPassword: '',
    currentPassword: '',
  });

  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(true);
  const [restoreData, setRestoreData] = useState(data);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  const correctDataRef = {
    email: true,
    name: true,
    lastName: true,
    cityInstitution: true,
    RFC: true,
    billingName: true,
    cityBilling: true,
    emailBilling: true,
  };

  const [notification, setNotification] = useState(data.notifyEmail);

  const editText = () => {
    setEdit(false);
  };

  const cancelEdit = () => {
    setUserData(restoreData);
    setEdit(true);
  };

  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.value !== null || event.target.value !== '') {
      setUserData({ ...data, [event.target.name]: event.target.value });
    }
  };

  const handleNotification = (event) => {
    event.preventDefault();
    setNotification(event.target.value);
    updateNotifications(event.target.value);
  };

  const handlePassword = (event) => {
    event.preventDefault();
    setPasswords({ ...passwords, [event.target.name]: event.target.value });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('es-MX', { month: 'long' });
    const year = date.toLocaleString('es-MX', { year: 'numeric' });

    const monthFormatted = month.charAt(0).toUpperCase() + month.slice(1);

    return `${monthFormatted} ${year}`;
  };

  const reauthenticate = (currentPassword) => {
    const cred = firebase.auth.EmailAuthProvider.credential(
      currentUser.email,
      currentPassword
    );
    return currentUser.reauthenticateWithCredential(cred);
  };

  const changePassword = () => {
    if (passwords.currentPassword === undefined) {
      setNotify({
        isOpen: true,
        message: 'No has ingresado la contraseña actual',
        type: 'error',
      });
    } else {
      reauthenticate(passwords.currentPassword)
        .then(() => {
          if (
            passwords.newPassword === passwords.confirmPassword &&
            passwords.newPassword !== ''
          ) {
            currentUser
              .updatePassword(passwords.newPassword)
              .then(() => {
                setNotify({
                  isOpen: true,
                  message: 'Contraseña actualizada con éxito',
                  type: 'success',
                });
              })
              .catch(() => {
                setNotify({
                  isOpen: true,
                  message: 'Error al cambiar la contraseña',
                  type: 'error',
                });
              });
          } else {
            setNotify({
              isOpen: true,
              message: 'Las contraseñas nuevas no coinciden',
              type: 'error',
            });
          }
          if (
            passwords.newPassword === undefined &&
            passwords.confirmPassword === undefined
          ) {
            setNotify({
              isOpen: true,
              message: 'Campos de contraseña nueva vacíos',
              type: 'error',
            });
          }
        })
        .catch(() => {
          if (
            passwords.newPassword === undefined &&
            passwords.confirmPassword === undefined
          ) {
            setNotify({
              isOpen: true,
              message: 'Campos de contraseña nueva vacíos',
              type: 'error',
            });
          } else {
            setNotify({
              isOpen: true,
              message: 'Contraseña actual incorrecta',
              type: 'error',
            });
          }
        });
      setPasswords({
        allPasswords: false,
        newPassword: '',
        confirmPassword: '',
        currentPassword: '',
      });
    }
  };

  const onClickSaveEdit = () => {
    if (!onlyChar.test(data.name)) {
      setNotify({
        isOpen: true,
        message: 'Solo se pueden colocar letras en el nombre',
        type: 'error',
      });
      correctDataRef.name = false;
    } else {
      correctDataRef.name = true;
    }

    if (!onlyChar.test(data.lastName)) {
      setNotify({
        isOpen: true,
        message: 'Solo se pueden colocar letras en el apellido',
        type: 'error',
      });
      correctDataRef.lastName = false;
    } else {
      correctDataRef.lastName = true;
    }

    if (!data.adminUser) {
      if (!onlyChar.test(data.cityInstitution)) {
        setNotify({
          isOpen: true,
          message: 'Solo se pueden colocar letras en el en la ciudad',
          type: 'error',
        });
        correctDataRef.cityInstitution = false;
      } else {
        correctDataRef.cityInstitution = true;
      }
    }
    if (!data.adminUser) {
      if (!alphaNum.test(data.RFC)) {
        setNotify({
          isOpen: true,
          message: 'Solo se pueden colocar letras y numeros en el RFC',
          type: 'error',
        });
        correctDataRef.RFC = false;
      } else {
        correctDataRef.RFC = true;
      }
    }
    if (!data.adminUser) {
      if (!onlyChar.test(data.billingName)) {
        setNotify({
          isOpen: true,
          message:
            'Solo se pueden colocar letras en el en el nombre de facturacion',
          type: 'error',
        });
        correctDataRef.billingName = false;
      } else {
        correctDataRef.billingName = true;
      }
    }
    if (!data.adminUser) {
      if (!onlyChar.test(data.cityBilling)) {
        setNotify({
          isOpen: true,
          message:
            'Solo se pueden colocar letras en el en la ciudad de facturacion',
          type: 'error',
        });
        correctDataRef.cityBilling = false;
      } else {
        correctDataRef.cityBilling = true;
      }
    }
    if (!data.adminUser) {
      if (!data.emailBilling) {
        setNotify({
          isOpen: true,
          message: 'Campo de correo en facturacion no puede estar vacío',
          type: 'error',
        });
        correctDataRef.emailBilling = false;
      } else if (!regexMail.test(data.emailBilling)) {
        setNotify({
          isOpen: true,
          message: 'El correo en facturacion tiene un formato no permitido',
          type: 'error',
        });
        correctDataRef.emailBilling = false;
      } else {
        correctDataRef.emailBilling = true;
      }
    }
    if (data.adminUser) {
      correctDataRef.cityInstitution = true;
      correctDataRef.RFC = true;
      correctDataRef.cityBilling = true;
      correctDataRef.emailBilling = true;
    }
    if (
      correctDataRef.name &&
      correctDataRef.lastName &&
      correctDataRef.cityInstitution &&
      correctDataRef.RFC &&
      correctDataRef.cityBilling &&
      correctDataRef.emailBilling
    ) {
      if (data.adminUser) {
        saveNewMendexData(data, currentUser);
        setEdit(true);
      } else {
        saveNewClientData(data, currentUser);
        setEdit(true);
      }
    }
  };

  const uploadHandler = async (files) => {
    const image = files[0];

    const uploadRef = storage.ref().child(`AvatarImage/${image.name}`);

    await uploadRef
      .put(image)
      .then(() => {
        setNotify({
          isOpen: true,
          message: 'La imagen se subio correctamente ',
          type: 'success',
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            'Hubo un problema a al tratar de subir la imagen, intentalo mas tarde ',
          type: 'error',
        });
        console.error(error);
      });

    await uploadRef.getDownloadURL().then((url) => {
      setUserData({ ...data, profilePic: url });
    });
  };

  return (
    <Grid container className={classes.root}>
      <Grid container item direction="column">
        <Grid container item justifyContent="space-between">
          <Grid item>
            {edit ? (
              <Typography className={classes.profileFullName}>
                {data.name + ' ' + data.lastName}
              </Typography>
            ) : (
              <Typography className={classes.profileFullName}>
                Editar perfil
              </Typography>
            )}
          </Grid>
          <Grid item className={edit ? classes.showInfo : classes.hiddeInfo}>
            <Button
              className={classes.buttonAqua}
              value={edit}
              onClick={editText}
            >
              Editar perfil
            </Button>
          </Grid>
          <Grid item className={edit ? classes.hiddeInfo : classes.showInfo}>
            <Button
              className={classes.buttonAqua}
              value={edit}
              onClick={() => {
                onClickSaveEdit();
              }}
            >
              Guardar
            </Button>
            <Button
              className={classes.cancelButton}
              value={edit}
              onClick={cancelEdit}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <Typography className={classes.titles}>Información</Typography>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid
          container
          item
          direction="row"
          xs={12}
          className={classes.containers}
        >
          <Grid container item xs={6} direction="row" >
            <Grid item xs={3}>
              <Avatar
                alt="Profile pic"
                src={data.profilePic}
                className={classes.profilePic}
              />
              <Grid
                container
                className={edit ? classes.hiddeInfo : classes.showInfo}
              >
                <Button
                  variant="contained"
                  component="label"
                  className={classes.buttonImg}
                  onClick={() => setOpen(true)}
                >
                  subir foto
                </Button>
                <DropzoneDialog
                  acceptedFiles={['image/*']}
                  cancelButtonText={'cancel'}
                  submitButtonText={'submit'}
                  maxFileSize={5000000}
                  open={open}
                  onClose={() => setOpen(false)}
                  onSave={(files) => {
                    uploadHandler(files);
                    setOpen(false);
                  }}
                  showPreviews={true}
                  showFileNamesInPreview={true}
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="column"
              xs={7}
              className={classes.personalInfoContainer}
            >
              <Grid
                item
                className={edit ? classes.showInfo : classes.hiddeInfo}
              >
                <Typography className={classes.fullName}>
                  {data.name + ' ' + data.lastName}
                </Typography>
              </Grid>
              <Grid
                item
                className={edit ? classes.hiddeInfo : classes.showInfo}
              >
                <Typography className={classes.subtitles1}>Nombre</Typography>
                <TextField
                  InputProps={{
                    className: classes.dataField,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.dataField}
                  variant="outlined"
                  type="text"
                  name="name"
                  value={data.name}
                  onChange={handleChange}
                ></TextField>
                <Typography className={classes.subtitles1}>Apellidos</Typography>
                <TextField
                  InputProps={{
                    className: classes.dataField,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className={classes.dataField}
                  variant="outlined"
                  type="text"
                  name="lastName"
                  value={data.lastName}
                  onChange={handleChange}
                ></TextField>
              </Grid>
              <Grid
                item
                className={edit ? classes.showInfo : classes.hiddeInfo}
              >
                <Typography className={classes.registerDate}>
                  Usuario desde {formatDate(data.registerDate)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={6} direction="column">
            {data.adminUser ? (
              <>
                <Grid item>
                  <Typography className={classes.subtitles}>
                    Institución
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.text}>
                    {data.institution}
                  </Typography>
                </Grid>
              </>
            ) : null}

            {data.adminUser ? null : (
              <>
                <Grid item >
                  <Typography className={classes.subtitles}>
                    Institución
                  </Typography>
                </Grid>
              </>
            )}
            <Grid container item direction="row" className = {classes.fields}>
              <Grid item direction="column">
                {data.adminUser ? null : (
                  <>
                    <Grid container item>
                      <Grid item style={{ paddingRight: '10px' }}>
                        <Typography
                          className={edit ? classes.text : classes.textInput}
                        >
                          Nombre
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        className={edit ? classes.showInfo : classes.hiddeInfo}
                      >
                        <Typography className={classes.text}>
                          {data.institution}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item>
                        <Typography
                          className={edit ? classes.text : classes.textInput}
                        >
                          Direccion
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        className={edit ? classes.showInfo : classes.hiddeInfo}
                      >
                        <Typography className={classes.text}>
                          {data.instituteAddress}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item style={{ paddingRight: '48px' }}>
                        <Typography
                          className={edit ? classes.text : classes.textInput}
                        >
                          C.P.
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        className={edit ? classes.showInfo : classes.hiddeInfo}
                      >
                        <Typography className={classes.text}>
                          {data.CPInstitution}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item>
                      <Grid item style={{ paddingRight: '20px' }}>
                        <Typography
                          className={edit ? classes.text : classes.textInput}
                        >
                          Ciudad
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        className={edit ? classes.showInfo : classes.hiddeInfo}
                      >
                        <Typography className={classes.text}>
                          {data.cityInstitution}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>

              <Grid
                item
                direction="column"
                className={edit ? classes.hiddeInfo : classes.showInfo}
              >
                {data.adminUser ? null : (
                  <>
                    <Grid>
                      <TextField
                        InputProps={{
                          className: classes.dataField,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.dataField}
                        variant="outlined"
                        name="institution"
                        type="text"
                        value={data.institution}
                        onChange={handleChange}
                      ></TextField>
                    </Grid>
                    <Grid>
                      <TextField
                        InputProps={{
                          className: classes.dataField,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.dataField}
                        variant="outlined"
                        name="instituteAddress"
                        type="text"
                        value={data.instituteAddress}
                        onChange={handleChange}
                      ></TextField>
                    </Grid>
                    <Grid>
                      <TextField
                        InputProps={{
                          className: classes.dataField,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.dataField}
                        variant="outlined"
                        name="CPInstitution"
                        type="number"
                        value={data.CPInstitution}
                        onChange={handleChange}
                      ></TextField>
                    </Grid>
                    <Grid>
                      <TextField
                        InputProps={{
                          className: classes.dataField,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.dataField}
                        variant="outlined"
                        name="cityInstitution"
                        type="text"
                        value={data.cityInstitution}
                        onChange={handleChange}
                      ></TextField>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container item className={classes.containers} >
          <Grid container item xs={6} direction="column" >
            <Grid item>
              <Typography className={classes.subtitles1}>Contacto</Typography>
            </Grid>
            <Grid container item direction="row">
              <Grid item direction="column">
                <Grid container item>
                  <Grid item style={{ paddingRight: '80px' }}>
                    <Typography
                      className={edit ? classes.text : classes.textInput}
                    >
                      Telefono
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={edit ? classes.showInfo : classes.hiddeInfo}
                  >
                    <Typography className={classes.text}>
                      {data.phone}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item style={{ paddingRight: '92px' }}>
                    <Typography
                      className={edit ? classes.text : classes.textInput}
                    >
                      Celular
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={edit ? classes.showInfo : classes.hiddeInfo}
                  >
                    <Typography className={classes.text}>
                      {data.cellPhone}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item>
                    <Typography
                      className={edit ? classes.text : classes.textInput}
                    >
                      Correo Electronico
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={edit ? classes.showInfo : classes.hiddeInfo}
                  >
                    <Typography className={classes.text}>
                      {data.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                direction="column"
                className={edit ? classes.showInfo : classes.hiddeInfo}
              ></Grid>
              <Grid
                item
                direction="column"
                className={edit ? classes.hiddeInfo : classes.showInfo}
              >
                <Grid>
                  <TextField
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.dataField}
                    variant="outlined"
                    name="phone"
                    type="number"
                    value={data.phone}
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid>
                  <TextField
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.dataField}
                    variant="outlined"
                    name="cellPhone"
                    type="number"
                    value={data.cellPhone}
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid>
                  <TextField
                    disabled
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.dataField}
                    variant="outlined"
                    name="email"
                    type="email"
                    value={data.email}
                    onChange={handleChange}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid 
            container
            item
            xs={6}
            direction="column"
            className={data.adminUser ? classes.hiddeInfo : classes.showInfo}
          >
            <Grid item>
              <Typography className={classes.subtitles}>Facturación</Typography>
            </Grid>
            <Grid container item direction="row" className = {classes.fields}>
              <Grid item direction="column">
                <Grid container item>
                  <Grid item style={{ paddingRight: '78px' }}>
                    <Typography
                      className={edit ? classes.text : classes.textInput}
                    >
                      Nombre
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={edit ? classes.showInfo : classes.hiddeInfo}
                  >
                    <Typography className={classes.text}>
                      {data.billingName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item style={{ paddingRight: '68px' }}>
                    <Typography
                      className={edit ? classes.text : classes.textInput}
                    >
                      Direccion
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={edit ? classes.showInfo : classes.hiddeInfo}
                  >
                    <Typography className={classes.text}>
                      {data.billingAddress}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item style={{ paddingRight: '112px' }}>
                    <Typography
                      className={edit ? classes.text : classes.textInput}
                    >
                      RFC
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={edit ? classes.showInfo : classes.hiddeInfo}
                  >
                    <Typography className={classes.text}>{data.RFC}</Typography>
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item style={{ paddingRight: '120px' }}>
                    <Typography
                      className={edit ? classes.text : classes.textInput}
                    >
                      C.P
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={edit ? classes.showInfo : classes.hiddeInfo}
                  >
                    <Typography className={classes.text}>
                      {data.CPBilling}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item style={{ paddingRight: '90px' }}>
                    <Typography
                      className={edit ? classes.text : classes.textInput}
                    >
                      Ciudad
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={edit ? classes.showInfo : classes.hiddeInfo}
                  >
                    <Typography className={classes.text}>
                      {data.cityBilling}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item>
                    <Typography
                      className={edit ? classes.text : classes.textInput}
                    >
                      Correo Electronico
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={edit ? classes.showInfo : classes.hiddeInfo}
                  >
                    <Typography className={classes.text}>
                      {data.emailBilling}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                direction="column"
                className={edit ? classes.hiddeInfo : classes.showInfo}
              >
                <Grid>
                  <TextField
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.dataField}
                    variant="outlined"
                    name="billingName"
                    type="text"
                    value={data.billingName}
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid>
                  <TextField
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.dataField}
                    variant="outlined"
                    name="billingAddress"
                    type="text"
                    value={data.billingAddress}
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid>
                  <TextField
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.dataField}
                    variant="outlined"
                    name="RFC"
                    type="text"
                    value={data.RFC}
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid>
                  <TextField
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.dataField}
                    variant="outlined"
                    name="CPBilling"
                    type="number"
                    value={data.CPBilling}
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid>
                  <TextField
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.dataField}
                    variant="outlined"
                    name="cityBilling"
                    type="text"
                    value={data.cityBilling}
                    onChange={handleChange}
                  ></TextField>
                </Grid>
                <Grid>
                  <TextField
                    InputProps={{
                      className: classes.dataField,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.dataField}
                    variant="outlined"
                    type="email"
                    name="emailBilling"
                    value={data.emailBilling}
                    onChange={handleChange}
                  ></TextField>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container item style={{ paddingBottom: '90px' }}>
        <Grid
          container
          item
          className={edit ? classes.hiddeInfo : classes.showInfo}
          style={{ paddingBottom: '30px' }}
        >
          <Grid container item xs={6}>
            <Typography className={classes.titles}>
              Cambia tu contraseña
            </Typography>
          </Grid>
          <Grid item container xs = {12}>
            <Grid item xs={6}>
              <Typography className={classes.subtitles1}>
                Contraseña Anterior
              </Typography>
              <TextField
                InputProps={{
                  className: classes.dataField,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.dataField}
                variant="outlined"
                type="password"
                name="currentPassword"
                onChange={handlePassword}
              ></TextField>
              <Typography className={classes.subtitles1}>
                Contraseña Nueva
              </Typography>
              <TextField
                InputProps={{
                  className: classes.dataField,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.dataField}
                variant="outlined"
                type="password"
                name="newPassword"
                onChange={handlePassword}
              ></TextField>
            </Grid>
            <Grid item xs = {6}>
              <Typography className={classes.subtitles1}>
              Confirma tu Nueva Contraseña
              </Typography>
              <TextField
                InputProps={{
                  className: classes.dataField,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.dataField}
                variant="outlined"
                type="password"
                name="confirmPassword"
                onChange={handlePassword}
              ></TextField>
              <Grid item style={{ paddingTop: '30px' }}>
                <Button className={classes.buttonAqua} onClick={changePassword}>
                  Cambiar contraseña
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          className={edit ? classes.showInfo : classes.hiddeInfo}
        >
          <Grid item>
            <Typography className={classes.titles}>Notificaciónes</Typography>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <Typography className={classes.subtitles1}>
                Correo electrónico
              </Typography>
              <RadioGroup
                aria-label="mode"
                name="notifyEmail"
                value={notification === 'activado' ? 'activado' : 'desactivado'}
                onChange={handleNotification}
              >
                <FormControlLabel
                  value={'activado'}
                  control={<Radio />}
                  label={'Activar'}
                />
                <FormControlLabel
                  value={'desactivado'}
                  control={<Radio />}
                  label={'Desactivar'}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} position={'bottom'} />
    </Grid>
  );
};

export default Users;
