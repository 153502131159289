import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  description: {
    minHeight: '620px',
  },
  header: {
    fontSize: '3.25rem',
    padding: '40px 50px 50px 100px',
  },
  descriptionText: {
    backgroundColor: '#3ec5bb',
    color: 'white',
    padding: '40px 20px 40px 50px',
  },
  tomografo: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
  },
});

export default useStyles;
