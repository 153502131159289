import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, Menu, MenuItem } from '@material-ui/core';
import useStyles from './NotificationsStyles';
import {
  fetchNotificationsCollection,
  deleteNotification,
  updateSeenNotification,
} from '../../../Utilities/Notification/Notification';

import { ReportNotification } from './Notification/NotificationContainer';
import Dropdown from '../../../assets/icons/Dropdown (1).svg';

// prettier-ignore
const dummyNotifs = [
  { id: '0abHdrvTTLjhSPOOqXPU', createdAt: 'Sun Aug 01 2021 23:20:44 GMT-0600 (hora de verano del Pacífico de México)', text: 'El reporte del mes de noviembre del dosímetro #12345 está disponible.', isFav: false },
  { id: '18L26RUCJqmMrYpUyA8E', createdAt: 'Fri Jul 30 2021 00:15:41 GMT-0600 (hora de verano del Pacífico de México)', text: 'La solicitud para agendar servicio técnico ha sido aprobada.', isFav: false },
  { id: '3Zwyo2vGr88rRbYAWbue', createdAt: 'Thu Jul 29 2021 15:59:11 GMT-0600 (hora de verano del Pacífico de México)', text: 'Imagenología Mendex te ha enviado un mensaje.', isFav: false },
  { id: 'H9yhm9soYP0bjrM37gLo', createdAt: 'Mon Aug 02 2021 10:25:52 GMT-0600 (hora de verano del Pacífico de México)', text: 'El reporte del mes de noviembre del dosímetro #12345 está disponible.', isFav: false },
  { id: 'JEuuucpDYw9fHZcJ9xZ3', createdAt: 'Mon Jul 26 2021 16:36:11 GMT-0600 (hora de verano del Pacífico de México)', text: 'El reporte del mes de noviembre del dosímetro #12345 está disponible.', isFav: false },
];

const DropdownIcon = () => (
  <img src={Dropdown} style={{ padding: '0 0.25rem' }}></img>
);

const Notifications = ({ userData, setIsOpen, isOpen }) => {
  const classes = useStyles();
  const [menu, setMenu] = useState(null);
  const [filterDays, setFilterDays] = useState(1);
  const [notifications, setNotifications] = useState(dummyNotifs);

  const filterByDate = (dateString, offsetDays = 0, offsetMonths = 0) => {
    const date = new Date(dateString);
    const dateOffset = new Date();

    dateOffset.setMonth(dateOffset.getMonth() + offsetMonths);
    dateOffset.setDate(dateOffset.getDate() + offsetDays);

    return (
      date.getMonth() == dateOffset.getMonth() &&
      date.getDate() == dateOffset.getDate()
    );
  };

  const formatDate = (offsetDays = 0) => {
    const date = new Date();
    date.setDate(date.getDate() + offsetDays);
    const day = date.getDate();
    const month = date
      .toLocaleDateString('es-MX', { month: 'long' })
      .toLowerCase();
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const toggleFav = (notificationId) => {
    setNotifications((prevNotifications) => {
      const newNotifications = [...prevNotifications];
      let notificationIndex;
      newNotifications.map((notif, index) => {
        if (notif.id === notificationId) notificationIndex = index;
      });

      newNotifications[notificationIndex].isFav =
        !newNotifications[notificationIndex].isFav;

      return newNotifications;
    });
  };

  const handleMenuClose = () => {
    setMenu(null);
  };

  const handleMenuClick = (event) => {
    setMenu(event.currentTarget);
  };

  const getNotificationsSection = () => {
    const daysArray = filterDays === 1 ? [0, -1, -2, -3] : [filterDays];

    return daysArray.map((value) => (
      <Grid
        key={value}
        container
        item
        xs={12}
        className={classes.notificationsSection}
      >
        <Typography variant="h2" className={classes.subheader}>
          {value === 0 ? 'Hoy' : value === -1 ? 'Ayer' : formatDate(value)}
        </Typography>
        {notifications
          .filter((notif) => {
            return filterByDate(notif.createdAt, value);
          })
          .map((notif, index) => (
            <ReportNotification
              key={index}
              index={index}
              notification={notif}
              toggleFav={toggleFav}
              classes={classes}
              setIsOpen={setIsOpen}
            />
          ))}
      </Grid>
    ));
  };

  const handleChangeSeenNotifications = () => {
    notifications.map((notify) => {
      if(notify.isOpen){
        return;
      }
      updateSeenNotification(notify.id);
    });
  };

  useEffect(() => {
    fetchNotificationsCollection().then((dbNotifs) => {
      const notifs = [];
      const today = new Date();
      dbNotifs.forEach((notif) => {
        const expiresAt = new Date(notif.expiresAt);
        if (expiresAt <= today) {
          deleteNotification(notif.id).catch((error) => console.log(error));
          return;
        }

        if (notif.clientId === userData.id || userData.adminUser)
          notifs.push(notif);
      });
      setNotifications(notifs);
    });
  }, []);

  useEffect(()=>{
    notifications.map((notify) => {
      if (!notify.isOpen) {
        setIsOpen(false);
        return;
      }
    });
  },[notifications])



  return (
    <Grid container item xs={12}>
      <Typography variant="h1" className={classes.header}>
        Notificaciones
      </Typography>
      <Grid container item xs={12} justifyContent="flex-end">
        <Button
          className={classes.buttonAqua}
          onClick={handleChangeSeenNotifications}
        >
          Marcar como leidas
        </Button>
        <Button
          disableElevation
          variant="outlined"
          size="large"
          endIcon={<DropdownIcon />}
          onClick={handleMenuClick}
          className={classes.dropDown}
        >
          {filterDays === 1
            ? 'Todas'
            : filterDays === 0
            ? 'Hoy'
            : filterDays === -1
            ? 'Ayer'
            : formatDate(filterDays)}
        </Button>
      </Grid>
      {getNotificationsSection(filterDays)}

      {/* menu */}
      <Menu
        id="month-menu"
        anchorEl={menu}
        keepMounted
        open={Boolean(menu)}
        onClose={handleMenuClose}
        PaperProps={{ style: { maxHeight: '13.5rem' } }}
      >
        <MenuItem
          onClick={() => {
            setFilterDays(1);
            handleMenuClose();
          }}
        >
          Todas
        </MenuItem>
        {Array.from(new Array(30), (val, index) => 0 - index).map(
          (dayOffset) => (
            <MenuItem
              key={dayOffset}
              onClick={() => {
                setFilterDays(dayOffset);
                handleMenuClose();
              }}
            >
              {dayOffset === 0
                ? 'Hoy'
                : dayOffset === -1
                ? 'Ayer'
                : formatDate(dayOffset)}
            </MenuItem>
          )
        )}
      </Menu>
    </Grid>
  );
};

export default Notifications;
