import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import useStyles from './NavbarStyles';

// SVGs
import Logo from '../../../assets/icons/LOGO_MENDEX.svg';

const Navbar = () => {
  const classes = useStyles();

  const LoginButton = () => {
    return (
      <Button
        href="/login"
        size="large"
        color="primary"
        variant="contained"
        className={`${classes.navBarButton} ${classes.loginButton}`}
      >
        Iniciar Sesion
      </Button>
    );
  };

  return (
    <Grid container justifyContent="space-between" className={classes.navBar}>
      <Grid item xs={3}>
        <img src={Logo} className={classes.logo}></img>
      </Grid>
      <Grid
        container
        item
        justifyContent="space-between"
        className={classes.navBarButtons}
        xs={6}
      >
        <Grid item className={classes.navBarButton}>
          <ScrollLink to="nosotros" smooth={true}>
            Nosotros
          </ScrollLink>
        </Grid>
        <Grid item className={classes.navBarButton}>
          <ScrollLink to="servicios" smooth={true}>
            Servicio
          </ScrollLink>
        </Grid>
        <Grid item className={classes.navBarButton}>
          <ScrollLink to="contacto" smooth={true}>
            Contacto
          </ScrollLink>
        </Grid>
        <Grid item>
          <RouterLink to="/login" component={LoginButton} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Navbar;
