import firebase, { db } from '../Firebase';

const notificationsRef = db.collection('Notifications');

export default class Notification {
  constructor(
    text,
    client,
    createdAt,
    expiresAt,
    type,
    objectId = null,
    isFav,
    isOpen
  ) {
    this.text = text;
    this.client = client;
    this.createdAt = createdAt;
    this.expiresAt = expiresAt;
    this.type = type;
    this.objectId = objectId;
    this.isFav = isFav;
    this.isOpen = isOpen;
  }
}

const notificationConverter = {
  toFirestore: (report) => {
    return {
      text: report.text,
      client: report.client,
      createdAt: report.createdAt,
      expiresAt: report.expiresAt,
      type: report.type,
      objectId: report.objectId,
      isFav: report.isFav,
      isOpen: report.isOpen,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new Notification(
      data.text,
      data.client,
      data.createdAt,
      data.expiresAt,
      data.type,
      data.objectId,
      data.isFav,
      data.isOpen
    );
  },
};

export const updateSeenNotification = (notificationId) =>{
  notificationsRef
  .doc(notificationId)
  .update({
    isOpen:true,
  })
  .then(() => {
    console.log("notificacion actualizada")
  })
  .catch((error) => {
    console.log(error.message)
  });
}

export const createNotification = (notification) => {
  return new Promise((resolve, reject) => {
    notificationsRef
      .doc()
      .withConverter(notificationConverter)
      .set(notification)
      .then(() => resolve())
      .catch((error) => reject(error));
  });
};

export const deleteNotification = (notificationId) => {
  return new Promise((resolve, reject) => {
    notificationsRef
      .doc(notificationId)
      .delete()
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const fetchNotificationsCollection = () => {
  return new Promise((resolve, reject) => {
    notificationsRef
      .get()
      .then((querySnapshot) => {
        const notifArray = [];
        querySnapshot.forEach((notif) => {
          notifArray.push({ id: notif.id, ...notif.data() });
        });
        resolve(notifArray);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
