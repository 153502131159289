import React, { useState, useEffect, useRef } from 'react';
import 'moment-timezone';
import {
  Button,
  Grid,
  Typography,
  TextField,
  OutlinedInput,
  Menu,
  MenuItem,
} from '@material-ui/core';
import Dropdown from '../../../../assets/icons/Dropdown (1).svg';
import FileInput from '../../../../Shared/FileInput/FileInput';
import { useAuth } from '../../../../Contexts/AuthContext';
import useStyles from './EditReportStyles';
import { editReport, uploadFile } from '../../../../Utilities/Report/Report';

// prettier-ignore
const services = ['Servicio de Dosimetría', 'Servicio de Rayos-X', 'Servicio de Mantenimiento','Servicio de capacitacion','Servicio de certificacion','Servicio de asesorias','Servicio de venta',"Mandiles para rayos X"];
// prettier-ignore
let clients = [];

const DropdownIcon = () => (
  <img src={Dropdown} style={{ padding: '0 0.25rem' }}></img>
);

const EditReport = ({ doc, setIsEditing, setNotify }) => {
  const [date, setDate] = useState('');
  const [client, setClient] = useState(doc.client);
  const [service, setService] = useState(doc.service);
  const [menu, setMenu] = useState({ element: null, type: null });
  const { fetchClients } = useAuth();

  const file = useRef(null);
  const classes = useStyles();

  const validate = () => {
    if (client === 'Selecciona un cliente') return false;
    if (service === 'Selecciona un servicio') return false;

    return true;
  };

  const formatDate = () => {
    const date = new Date();
    const day = date.toLocaleString('es-MX', { day: '2-digit' });
    const month = date.toLocaleString('es-MX', { month: 'long' });
    const year = date.toLocaleString('es-MX', { year: 'numeric' });

    return `${day} ${month} ${year}`;
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleChangeFile = (newFile) => {
    file.current = newFile;
  };

  const handlePublish = async () => {
    if (validate()) {
      let edited = false;
      let propertiesToUpdate = {
        id: doc.id,
      };
      // Check if client or service changed
      if (doc.client !== client) {
        propertiesToUpdate.client = client;
        edited = true;
      } else if (doc.service !== service) {
        propertiesToUpdate.service = service;
        edited = true;
      } else if (doc.date !== date) {
        propertiesToUpdate.date = date;
        edited = true;
      }

      // Check if there is a new file
      if (file.current) {
        await uploadFile(file.current)
          .then(async (snapshot) => {
            propertiesToUpdate.fileUrl = await snapshot.ref.getDownloadURL();
            edited = true;
          })
          .catch((error) => {
            console.error(error);
          });
      }

      // If edited, add a property editedAt with a timestamp and edit the document
      if (edited) {
        propertiesToUpdate.editedAt = new Date();
        editReport(doc.id, propertiesToUpdate)
          .then(() => {
            setNotify({
              isOpen: true,
              type: 'success',
              message: 'Se actualizó el documento exitosamente.',
            });
            setIsEditing(false);
          })
          .catch(() => {
            setNotify({
              isOpen: true,
              type: 'error',
              message: 'No se pudo actualizar el documento.',
            });
          });
      } else {
        setNotify({
          isOpen: true,
          type: 'info',
          message: 'No hay campos que actualizar.',
        });
        setIsEditing(false);
      }
    }
  };

  const handleMenuClick = (event, type) => {
    setMenu({ element: event.currentTarget, type: type });
  };

  const handleMenuItemClick = (type, value) => {
    switch (type) {
      case 'client':
        setClient(value);
        break;
      case 'service':
        setService(value);
        break;
    }
    handleClose();
  };

  const handleClose = () => {
    setMenu({ element: null, type: null });
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  useEffect(async () => {
    clients = await fetchClients();
    console.log(clients);
  }, []);

  return (
    <Grid container item>
      <Typography variant="h2" className={classes.header}>
        Editar Reporte {doc.invoice}
      </Typography>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: '5rem' }}
      >
        <Grid item></Grid>
        <Grid>
          <Button
            variant="contained"
            disableElevation
            className={classes.publishButton}
            onClick={handlePublish}
          >
            <Typography>Publicar</Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid container item xs={6} alignItems="center">
          <Typography variant="h6" className={classes.label}>
            Cliente
          </Typography>
          <Button
            disableElevation
            variant="outlined"
            size="large"
            endIcon={<DropdownIcon />}
            onClick={(e) => handleMenuClick(e, 'client')}
            className={`${classes.dropDown} ${classes.outlinedButton}`}
          >
            <Typography>
              {client ? `${client.Name} ${client.LastName}` : doc.createdBy}
            </Typography>
          </Button>
        </Grid>
        <Grid container item xs={6} alignItems="center">
          <Typography variant="h6" className={classes.label}>
            Servicio
          </Typography>
          <Button
            disableElevation
            variant="outlined"
            size="large"
            endIcon={<DropdownIcon />}
            onClick={(e) => handleMenuClick(e, 'service')}
            className={`${classes.dropDown} ${classes.outlinedButton}`}
          >
            <Typography>{service}</Typography>
          </Button>
        </Grid>
        <Grid
          container
          item
          xs={6}
          alignItems="center"
          style={{ paddingTop: '15px' }}
        >
          <Typography variant="h6" className={classes.label}>
            Fecha de Creacion
          </Typography>
          <TextField
            variant="outlined"
            required
            id="endDate"
            type="date"
            placeholder="AAAA-MM-DD"
            name="docDate"
            size="small"
            InputProps={{
              inputProps: { min: '2015-01-01', max: '2100-01-01' },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              handleDateChange(e);
            }}
          />
        </Grid>
        <FileInput setFile={handleChangeFile} />
      </Grid>

      <Grid container justifyContent="space-between">
        <Grid container justifyContent="flex-end">
          <Button
            variant="outlined"
            className={classes.outlinedButton}
            onClick={handleCancel}
          >
            <Typography>Cancelar</Typography>
          </Button>
          <Button
            variant="contained"
            disableElevation
            className={classes.publishButton}
            onClick={handlePublish}
          >
            <Typography style={{ margin: 0 }}>Publicar</Typography>
          </Button>
        </Grid>
      </Grid>

      {/* Client  menu */}
      <Menu
        id="client-menu"
        anchorEl={menu.element}
        keepMounted
        open={Boolean(menu.element) && menu.type === 'client'}
        onClose={handleClose}
        PaperProps={{ style: { maxHeight: '13.5rem' } }}
      >
        {clients.map((individualClient, index) => (
          <MenuItem
            key={index}
            onClick={() => handleMenuItemClick('client', individualClient)}
          >
            {`${individualClient.Name} ${individualClient.LastName}`}
          </MenuItem>
        ))}
      </Menu>
      {/* Service  menu */}
      <Menu
        id="service-menu"
        anchorEl={menu.element}
        keepMounted
        open={Boolean(menu.element) && menu.type === 'service'}
        onClose={handleClose}
        PaperProps={{ style: { maxHeight: '13.5rem' } }}
      >
        {services.map((individualService, index) => (
          <MenuItem
            key={index}
            onClick={() => handleMenuItemClick('service', individualService)}
          >
            {individualService}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export default EditReport;
