import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Typography,
  Avatar,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import useStyles from './EditClientStyles';
import PublishIcon from '@material-ui/icons/Publish';
import { DropzoneDialog } from 'material-ui-dropzone';
import Notification from '../../../../Helpers/Notification';
import { storage } from '../../../../Utilities/Firebase';
import { onlyChar, onlyNum } from '../../../../Helpers/Regex';
import { UpdateClientInfo } from '../../../../Utilities/Client/Client';

const EditClient = ({ client, setIsEditing }) => {
  const classes = useStyles();

  const history = useHistory();

  const [data, setData] = useState(client);
  const [open, setOpen] = useState(false);
  const [services,setServices] = useState()

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  const dummyServices ={
    dosimetria: false,
    rayosx: false,
    mantenimiento: false,
    capacitaciones: false,
    certificados: false,
    asesorias: false,
    venta: false,
  }

  const correctData = {
    institution: true,
    address: true,
    name: true,
    lastName: true,
    cellPhone: true,
    phone: true,
  };

  const handleChange = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleCheck = (e) => {
    e.preventDefault();
    setServices({ ...services, [e.target.name]: e.target.checked });
  };

  const onClickverifyData = (data, services) => {
    if (!data.institution) {
      setNotify({
        isOpen: true,
        message: 'Campo de Institución no puede estar vacío',
        type: 'error',
      });
      correctData.institution = false;
    } else {
      correctData.institution = true;
    }

    if (!data.institutionaddress) {
      setNotify({
        isOpen: true,
        message: 'Campo de direccion no puede estar vacío',
        type: 'error',
      });
      correctData.address = false;
    } else {
      correctData.address = true;
    }

    if (!data.name) {
      setNotify({
        isOpen: true,
        message: 'Campo de nombre no puede estar vacío',
        type: 'error',
      });
      correctData.name = false;
    } else if (!onlyChar.test(data.name)) {
      setNotify({
        isOpen: true,
        message: 'Solo se pueden colocar letras en el nombre',
        type: 'error',
      });
      correctData.name = false;
    } else {
      correctData.name = true;
    }

    if (!data.lastName) {
      setNotify({
        isOpen: true,
        message: 'Campo de apellido no puede estar vacío',
        type: 'error',
      });
      correctData.lastName = false;
    } else if (!onlyChar.test(data.lastName)) {
      setNotify({
        isOpen: true,
        message: 'Solo se pueden colocar letras en el apellido',
        type: 'error',
      });
      correctData.lastName = false;
    } else {
      correctData.lastName = true;
    }

    if (!data.phone) {
      setNotify({
        isOpen: true,
        message: 'Campo de telefono no puede estar vacío',
        type: 'error',
      });
      correctData.phone = false;
    } else if (!onlyNum.test(data.phone)) {
      setNotify({
        isOpen: true,
        message: 'Solo pueden colocar numeros en el telefono',
        type: 'error',
      });
      correctData.phone = false;
    } else {
      correctData.phone = true;
    }

    if (!data.cellPhone) {
      setNotify({
        isOpen: true,
        message: 'Campo de celular no puede estar vacío',
        type: 'error',
      });
      correctData.cellPhone = false;
    } else if (!onlyNum.test(data.cellPhone)) {
      setNotify({
        isOpen: true,
        message: 'Solo pueden colocar numeros en el celular',
        type: 'error',
      });
      correctData.cellPhone = false;
    } else {
      correctData.cellPhone = true;
    }

    if (
      correctData.name &&
      correctData.lastName &&
      correctData.institution &&
      correctData.cellPhone &&
      correctData.phone
    ) {
      UpdateClientInfo(client, data, setNotify);
    }
  };

  const uploadHandler = async (files) => {
    const image = files[0];

    const uploadRef = storage.ref().child(`AvatarImage/${image.name}`);

    await uploadRef
      .put(image)
      .then((snapshot) => {
        setNotify({
          isOpen: true,
          message: 'La imagen se subio correctamente ',
          type: 'success',
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message:
            'Hubo un problema a al tratar de subir la imagen, intentalo mas tarde ',
          type: 'error',
        });
        console.error(error);
      });

    await uploadRef.getDownloadURL().then((url) => {
      setData({ ...data, profilePic: url });
    });
  };

  return (
    <Grid container item className={classes.root}>
      <Grid container item direction="row" xs={12}>
        <Grid item xs={6}>
          <Typography className={classes.createTitle}>
            Editar cliente
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button
            className={classes.buttonAqua}
            onClick={() => {
              onClickverifyData(data, services);
            }}
          >
            Guardar cambios
          </Button>
          <Button
            className={classes.cancelButton}
            onClick={() => {
              setIsEditing(false);
              history.push('/dashboard/clientes');
            }}
          >
            Cancelar
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Typography className={classes.title}>Información</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid item direction="column" xs={3}>
          <Grid container>
            <Avatar className={classes.avatar}>
              <PublishIcon className={classes.iconSize} />
            </Avatar>
          </Grid>
          <Grid container>
            <Button
              variant="contained"
              component="label"
              className={classes.buttonImg}
              onClick={() => setOpen(true)}
            >
              Subir foto
            </Button>
            <DropzoneDialog
              acceptedFiles={['image/*']}
              cancelButtonText={'cancel'}
              submitButtonText={'submit'}
              maxFileSize={5000000}
              open={open}
              onClose={() => setOpen(false)}
              onSave={(files) => {
                uploadHandler(files);
                setOpen(false);
              }}
              showPreviews={true}
              showFileNamesInPreview={true}
            />
          </Grid>
        </Grid>
        <Grid container item direction="row" xs={6}>
          <Grid container direction="column">
            <Typography className={classes.subtitle}>Nombre</Typography>
            <Grid>
              <TextField
                required
                type="text"
                InputProps={{
                  className: classes.dataField,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                className={classes.dataField}
                value={data.name}
                name="name"
                onChange={handleChange}
              ></TextField>
            </Grid>
            <Typography className={classes.subtitle}>Apellido</Typography>
            <Grid>
              <TextField
                required
                type="text"
                InputProps={{
                  className: classes.dataField,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                className={classes.dataField}
                value={data.lastName}
                name="lastName"
                onChange={handleChange}
              ></TextField>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="row"
        xs={12}
        className={classes.container}
      >
        <Grid container item direction="column" xs={6}>
          <Grid item>
            <Typography className={classes.subtitle}>
              Informacion personal
            </Typography>
          </Grid>

          <Grid container direction="row">
            <Grid item direction="column">
              <Typography className={classes.text}>Institución</Typography>
              <Typography className={classes.text}>
                Direccion Institucion
              </Typography>
              <Typography className={classes.text}>Telefono</Typography>
              <Typography className={classes.text}>Celular</Typography>
            </Grid>
            <Grid item direction="column">
              <Grid item>
                <TextField
                  required
                  type="text"
                  InputProps={{
                    className: classes.dataField,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  className={classes.dataField}
                  value={data.institution}
                  name="institution"
                  onChange={handleChange}
                ></TextField>
              </Grid>
              <Grid>
                <TextField
                  required
                  type="text"
                  InputProps={{
                    className: classes.dataField,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  className={classes.dataField}
                  value={data.institutionaddress}
                  name="address"
                  onChange={handleChange}
                ></TextField>
              </Grid>
              <Grid>
                <TextField
                  required
                  type="text"
                  InputProps={{
                    className: classes.dataField,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  className={classes.dataField}
                  value={data.phone}
                  name="phone"
                  onChange={handleChange}
                ></TextField>
              </Grid>
              <Grid>
                <TextField
                  required
                  type="text"
                  InputProps={{
                    className: classes.dataField,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  className={classes.dataField}
                  value={data.cellPhone}
                  name="cellPhone"
                  onChange={handleChange}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item direction="column" xs={6} className = {classes.servicios}>
          <Grid item>
            <FormControl name="services">
              <Typography className={classes.subtitle}>Servicio</Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={client.servicesBool.dosimetria}
                      onChange={handleCheck}
                      name="dosimetria"
                    />
                  }
                  label="Dosimetría"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={client.servicesBool.rayosx}
                      onChange={handleCheck}
                      name="rayosx"
                    />
                  }
                  label="Rayos-X"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={client.servicesBool.capacitaciones}
                      onChange={handleCheck}
                      name="capacitaciones"
                    />
                  }
                  label="Capacitaciones"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={client.servicesBool.certificados}
                      onChange={handleCheck}
                      name="certificados"
                    />
                  }
                  label="Certificados"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={client.servicesBool.asesorias}
                      onChange={handleCheck}
                      name="asesorias"
                    />
                  }
                  label="Asesorias"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={client.servicesBool.venta}
                      onChange={handleCheck}
                      name="venta"
                    />
                  }
                  label="Venta de equipo"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={client.servicesBool.mantenimiento}
                      onChange={handleCheck}
                      name="mantenimiento"
                    />
                  }
                  label="Mantenimiento"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} position={'bottom'} />
    </Grid>
  );
};

export default EditClient;
