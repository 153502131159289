import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Notification from '../../../../Helpers/Notification';
import useStyles from './IndividualReportStyles';
import EditReport from '../EditReport/EditReport';
import { fetchReportById } from '../../../../Utilities/Report/Report';
import LinearProgress from '@material-ui/core/LinearProgress';

const Bullet = ({ classes }) => {
  return <span className={classes.bullet}>•</span>;
};

const IndividualReport = ({
  docId,
  isEditing,
  setIsEditing,
  setIsDocActive,
  userData,
}) => {
  const [doc, setDoc] = useState(null);
  const [notify, setNotify] = useState({
    isOpen: false,
    type: 'info',
    message: '',
  });
  const classes = useStyles();
  const history = useHistory();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('es-MX', { month: 'long' });
    const year = date.toLocaleString('es-MX', { year: 'numeric' });

    const monthFormatted = month.charAt(0).toUpperCase() + month.slice(1);

    return `${monthFormatted} ${year}`;
  };

  const handleGoBack = () => {
    setIsDocActive(false);
    history.push('/dashboard/reportes');
  };

  const handleExport = () => {
    if (doc.fileUrl) {
      fetch(doc.fileUrl)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Reporte-${doc.invoice}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
    } else {
      setNotify({
        isOpen: true,
        type: 'error',
        message: 'No se pudo obtener el link del archivo.',
      });
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    fetchReportById(docId)
      .then((report) => {
        console.log(report);
        setDoc(report);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // If there is a doc, check if isEditing and render the respective component, else set a loading header
  return doc ? (
    isEditing ? (
      <EditReport doc={doc} setIsEditing={setIsEditing} setNotify={setNotify} />
    ) : (
      <Grid container item>
        {notify.isOpen && (
          <Notification notify={notify} setNotify={setNotify} position="top" />
        )}
        <Grid container item xs={12}>
          <Grid container item xs={6}>
            <Typography variant="h1" className={classes.header}>
              Reporte {doc.invoice}
            </Typography>
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <Button
              size="large"
              className={classes.outlinedButton}
              onClick={handleGoBack}
            >
              Cerrar
            </Button>
            <Button
              size="large"
              className={classes.filledButton}
              onClick={handleExport}
            >
              Exportar
            </Button>
            {userData.adminUser ? (
              <Button
                size="large"
                className={classes.filledButton}
                onClick={handleEditClick}
              >
                Editar
              </Button>
            ) : null}
          </Grid>
        </Grid>

        <Grid container>
          <Grid container spacing={1}>
            <Typography className={classes.docProps}>{doc.service}</Typography>
            <Bullet classes={classes} />
            <Typography className={classes.docProps}>
              {doc.institution}
            </Typography>
            <Bullet classes={classes} />
            <Typography className={classes.docProps}>#{doc.invoice}</Typography>
            <Bullet classes={classes} />
          </Grid>
          <Grid container spacing={1}>
            <Typography className={classes.docProps}>
              Fecha de publicacion: {formatDate(doc.createdAt)}
            </Typography>
            <Bullet classes={classes} />
            <Typography className={classes.docProps}>
              Fecha de creacion: {doc.date}
            </Typography>
          </Grid>

        </Grid>
        <Grid className={classes.text}>
          <Typography>{doc.text}</Typography>
        </Grid>
      </Grid>
    )
  ) : (
    <LinearProgress color="secondary" />
  );
};

export default IndividualReport;
