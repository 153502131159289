import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../Contexts/AuthContext';

const ProtectedRoute = ({ component: Component, path, ...rest }) => {
  const { currentUser } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) => {
        return currentUser ? (
          <>
            <Component {...props} {...rest} />
          </>
        ) : (
          <Redirect to="/login" />
        );
      }}
    ></Route>
  );
};

export default ProtectedRoute;
