import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

  root: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: '3.5rem'
  },

  clientFullName: {
    fontSize: '40px',
    fontWeight: 'bold',
  },

  titles: {
    fontSize: '25px',
    fontWeight: 'bold',
    paddingBottom: '1rem',
  },

  profilePic: {
    width: '180px',
    height: '180px',
    fontSize: '20px',
    backgroundColor: '#9be4df',
  },

  subtitles: {
    fontSize: '20px',
    [theme.breakpoints.down('1600')]: {
      marginLeft: '1.5rem',
    },
    [theme.breakpoints.down('1250')]: {
      marginLeft: '3rem',
    },
  },

  text: {
    color: 'rgba(0, 0, 0, 0.6)',
    [theme.breakpoints.down('1600')]: {
      marginLeft: '1.5rem',
    },
    [theme.breakpoints.down('1250')]: {
      marginLeft: '3rem',
    },
  },

  buttonAqua: {
    textTransform: 'none',
    padding: '10px 40px 10px 40px',
    background: '#3ec5bb',
    '&:hover': {
      backgroundColor: '#9be4df',
    },
    color: 'white',
    border: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    marginBottom: '30px',
  },

  cancelButton: {
    marginRight: '1rem',
    textTransform: 'none',
    padding: '10px 40px 10px 40px',
    background: '#ffffff',
    '&:hover': {
      backgroundColor: '#9be4df',
    },
    color: 'black',
    border: '2px solid #3ec5bb',
    fontWeight: 'bold',
    fontSize: '16px',
    marginBottom: '30px',
  },
}));

export default useStyles;
