import React, { useState } from 'react';
import { Button, Grid, Typography, Link, TextField } from '@material-ui/core';
import mendexLogo from '../../../assets/icons/LOGO_MENDEX.svg';
import useStyles from './LoginStyles.js';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../../Contexts/AuthContext';

import { auth } from '../../../Utilities/Firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ModalComponent from '../../../Helpers/Modal/Modal';
import Notification from '../../../Helpers/Notification';
import CloseIcon from '@material-ui/icons/Close';

const Login = () => {
  const classes = useStyles();

  const history = useHistory();
  const { login, resetPassword } = useAuth();
  const [open, setOpen] = useState(false);
  const [user] = useAuthState(auth);
  const location = useLocation();
  const [showPassword, setShowPassword] = useState('password');
  const [EmailRecoverPassword, setEmailRecoverPassword] = useState('');

  const [data, setData] = useState({
    password: '',
    email: '',
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  const handleModal = () => {
    setOpen(!open);
  };

  if (user && location.pathname == '/login') {
    history.replace('/dashboard/reportes');
  }

  const sendToLanding = () => {
    history.push('/');
  };

  const handleChange = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onLoginSubmit = async (e) => {
    e.preventDefault();
    if (data.password !== '' || data.email !== '') {
      try {
        await login(data);
        history.push('/dashboard/reportes');
      } catch {}
    } else {
      setNotify({
        isOpen: true,
        message: 'Ingresa tu informacion para iniciar sesion',
        type: 'error',
      });
      setData({
        password: '',
        email: '',
      });
    }
  };

  const clearReset = () => {
    setEmailRecoverPassword('');
  };

  const handleRecoverPassword = (e) => {
    e.preventDefault();
    setEmailRecoverPassword(e.target.value);
  };

  const recoverPasswordModalText = (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid
        container
        item
        xs={12}
        style={{ paddingBottom: '15px', paddingTop: '15px' }}
      >
        <Grid item xs={11} />
        <Grid item xs={1}>
          <CloseIcon
            onClick={() => {
              setOpen(!open);
              clearReset();
            }}
            style={{ cursor: 'pointer' }}
          ></CloseIcon>
        </Grid>
      </Grid>
      <Grid item style={{ paddingBottom: '30px' }}>
        <Typography style={{ fontSize: '40px', fontWeight: 'bold' }}>
          Recupera tu contraseña
        </Typography>
      </Grid>
      <Grid item style={{ paddingBottom: '30px' }}>
        <Typography style={{ fontSize: '20px' }}>
          Escribe tu correo electrónico para restaurar tu contraseña
        </Typography>
      </Grid>
      <Grid container item justifyContent="center">
        <Grid item style={{ marginRight: '20px' }}>
          <Typography style={{ fontSize: '20px' }}>
            Correo Electronico
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            className={classes.dataField}
            InputProps={{
              className: classes.dataField,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            type="email"
            variant="outlined"
            name="email"
            label="Correo"
            fullWidth
            value={EmailRecoverPassword}
            onChange={handleRecoverPassword}
          ></TextField>
        </Grid>
      </Grid>
      <Grid container item justifyContent="center">
        <Button
          style={{ marginRight: '30px' }}
          className={classes.buttonAqua}
          onClick={() => {
            setOpen(!open);
            clearReset();
          }}
        >
          Cancelar
        </Button>
        <Button
          className={classes.buttonAqua}
          onClick={() => {
            resetPassword(EmailRecoverPassword);
            clearReset();
            setOpen(!open);
          }}
        >
          Recupera tu contraseña
        </Button>
      </Grid>
    </Grid>
  );
  return (
    <Grid container className={classes.root}>
      <Grid item className={classes.logoContainer} onClick={sendToLanding}>
        <img src={mendexLogo} alt="logoMendex" className={classes.logo}></img>
      </Grid>
      <form>
      <Grid container justifyContent="center" alignItems="center" className = {classes.centerElement}>
        <Grid item>
          <Typography className={classes.titleSession}>
            Inicio de <span className={classes.titleBold}>sesión</span>
          </Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Grid
            container
            item
            justifyContent="center"
            className={classes.emailContainer}
          >
            <Grid item>
              <Typography
                className={classes.textLabel}
                style={{ paddingRight: '40px' }}
              >
                Correo
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                className={classes.dataField}
                InputProps={{
                  className: classes.dataField,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                type="email"
                variant="outlined"
                name="email"
                fullWidth
                onChange={handleChange}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container item justifyContent="center">
            <Grid item>
              <Typography
                className={classes.textLabel}
                style={{ paddingRight: '55px' }}
              >
                Contraseña
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                className={classes.dataField}
                InputProps={{
                  className: classes.dataField,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                type={showPassword}
                required
                variant="outlined"
                name="password"
                fullWidth
                onChange={handleChange}
              ></TextField>
              <Button
                className={classes.viewButton}
                onClick={() => {
                  showPassword === 'password'
                    ? setShowPassword('text')
                    : setShowPassword('password');
                }}
              >
                {showPassword === 'password' ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon />
                )}
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              className={classes.buttonAqua}
              type="submit"
              onClick={onLoginSubmit}
            >
              Iniciar sesión
            </Button>
          </Grid>
        </Grid>
        <Grid className={classes.spaceContainer}>
          <Typography>
            ¿Olvidaste tu contraseña?
            <Link
              className={classes.link}
              style={{ paddingLeft: '5px' }}
              onClick={handleModal}
            >
              Oprime aqui para recuperarla
            </Link>
          </Typography>
        </Grid>
        <ModalComponent
          body={recoverPasswordModalText}
          handleModal={handleModal}
          open={open}
        />
      </Grid>
      </form>
      <Notification notify={notify} setNotify={setNotify} />
    </Grid>
  );
};

export default Login;
