import firebase, { db } from '../Firebase';

const clientsRef = db.collection('UserData');

export default class Client {
  constructor(
    adminUser,
    profilePic,
    email,
    name,
    lastName,
    cellPhone,
    phone,
    institution,
    institutionaddress,
    cpinstitution,
    cityinstitution,
    billingname,
    rfc,
    bullingaddress,
    cpbilling,
    citybilling,
    emailbilling,
    services,
    registerDate,
    servicesBool
  ) {
    this.adminUser = adminUser;
    this.profilePic = profilePic;
    this.email = email;
    this.name = name;
    this.lastName = lastName;
    this.cellPhone = cellPhone;
    this.phone = phone;
    this.institution = institution;
    this.institutionaddress = institutionaddress;
    this.cpinstitution = cpinstitution;
    this.cityinstitution = cityinstitution;
    this.billingname = billingname;
    this.rfc = rfc;
    this.bullingaddress = bullingaddress;
    this.cpbilling = cpbilling;
    this.citybilling = citybilling;
    this.emailbilling = emailbilling;
    this.services = services;
    this.registerDate = registerDate;
    this.servicesBool = servicesBool;
  }
}

const clientConverter = {
  toFirestore: (client) => {
    return {
      ProfilePic: client.profilePic,
      Email: client.email,
      Name: client.name,
      LastName: client.lastName,
      CellPhone: client.cellPhone,
      Phone: client.phone,
      Institution: client.institution,
      Institutionaddress: client.institutionaddress,
      CPInstitution: client.cpinstitution,
      CityInstitution: client.cityinstitution,
      BillingName: client.billingname,
      RFC: client.rfc,
      Billingaddress: client.billingaddress,
      CPBilling: client.cpbilling,
      CityBilling: client.citybilling,
      EmailBilling: client.emailbilling,
    };
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options);
    return new Client(
      data.AdminUser,
      data.ProfilePic,
      data.Email,
      data.Name,
      data.LastName,
      data.CellPhone,
      data.Phone,
      data.Institution,
      data.InstitutionAddress,
      data.CPInstitution,
      data.CityInstitution,
      data.BillingName,
      data.RFC,
      data.BillingAddress,
      data.CPBilling,
      data.CityBilling,
      data.EmailBilling,
      data.Services,
      data.RegisterDate,
      data.ServicesBool
    );
  },
};

export const UpdateClientInfo = (client, newData, setNotify) => {
  firebase
    .firestore()
    .collection('UserData')
    .doc(client.id)
    .update({
      ProfilePic: newData.profilePic,
      Name: newData.name,
      LastName: newData.lastName,
      CellPhone: newData.cellPhone,
      Phone: newData.phone,
      Institution: newData.institution,
      InstitutionAddress: newData.institutionaddress,
    })
    .then(() => {
      setNotify({
        isOpen: true,
        message: 'Informacion actualizada correctamente',
        type: 'success',
      });
    })
    .catch(() => {
      setNotify({
        isOpen: true,
        message:
          'Hubo un error al momento de actualizar los datos, intentalo mas tarde',
        type: 'error',
      });
    });
};

export const listenUsersCollection = (callback) => {
  const unsubscribe = clientsRef.onSnapshot((snapshot) => {
    callback(snapshot);
  });
  return unsubscribe;
};

export const fetchClientById = (clientId) => {
  return new Promise((resolve, reject) => {
    clientsRef
      .doc(clientId)
      .withConverter(clientConverter)
      .get()
      .then((doc) => {
        if (doc.exists) {
          // Convert to Report
          const client = { id: doc.id, ...doc.data() };
          resolve(client);
        } else {
          // doc.data() will be undefined in this case
          reject('No client was found');
        }
      })
      .catch((error) => {
        console.error('error error');
        reject(error);
      });
  });
};
