import React, { useEffect, useRef, useState } from 'react';
import momentTimezone from 'moment';
import 'moment-timezone';
import {
  Button,
  Grid,
  TextField,
  Typography,
  Menu,
  MenuItem,
  OutlinedInput,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../../Contexts/AuthContext';
import useStyles from './NewReportStyles';
import Report, {
  createReport,
  uploadFile,
} from '../../../../Utilities/Report/Report';
import Notification, {
  createNotification,
} from '../../../../Utilities/Notification/Notification';
import Dropdown from '../../../../assets/icons/Dropdown (1).svg';
import FileInput from '../../../../Shared/FileInput/FileInput';

// prettier-ignore
const months = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
// prettier-ignore
const services = ['Servicio de Dosimetría', 'Servicio de Rayos-X', 'Servicio de Mantenimiento','Servicio de capacitacion','Servicio de certificacion','Servicio de asesorias','Servicio de venta',"Mandiles para rayos X"];
// prettier-ignore
let clients = [];

const DropdownIcon = () => (
  <img src={Dropdown} style={{ padding: '0 0.25rem' }}></img>
);

const formatDate = (day, month, year) => {
  return `${day} ${months[month]} ${year}`;
};

const NewReport = ({ setIsNewActive, setNotify, userData }) => {
  const [client, setClient] = useState(null);
  const [service, setService] = useState('Selecciona un servicio');
  const [menu, setMenu] = useState({ element: null, type: null });
  const [date, setDate] = useState('');
  const history = useHistory();
  const file = useRef(null);
  const classes = useStyles();
  const { currentUser, fetchClients } = useAuth();

  const cleanData = () => {
    setClient(null);
    setService('Selecciona un servicio');
    setDate('');
  };

  const validate = () => {
    if (!client) return false;
    if (service === 'Selecciona un servicio') return false;

    return true;
  };

  const handleNavigateToReports = () => {
    setIsNewActive(false);
    history.push('/dashboard/reportes');
  };

  const handleChangeFile = (newFile) => {
    file.current = newFile;
  };

  const handlePublish = async () => {
    console.log(userData);
    if (validate()) {
      try {
        const docSnapshot = await uploadFile(file.current);
        const fileUrl = await docSnapshot.ref.getDownloadURL();
        const invoice = Math.floor(Math.random() * 100) + '-' + date;
        const today = new Date();
        const newExpiresAt = new Date(today);
        newExpiresAt.setDate(newExpiresAt.getDate() + 30);

        const newReport = new Report(
          client.id,
          client.Institution,
          today.toString(),
          client.Name + ' ' + client.LastName,
          invoice,
          service,
          '',
          date,
          fileUrl
        );

        // Once the fileUrl is set, create the report
        const reportId = await createReport(newReport);
        // Once the report is created, send a notification to the user
        createNotification(
          new Notification(
            `Esta es una notificacion generada al hacer el reporte ${invoice}`,
            currentUser.uid,
            today.toString(),
            newExpiresAt.toString(),
            'report',
            reportId,
            false,
            false
          )
        ).catch((error) => console.log(error));

        if (client.NotifyEmail == 'activado') {
          const data = {
            user: client,
            report: { id: reportId, ...newReport.toObject() },
          };
          fetch(
            'https://us-central1-mendex-4a355.cloudfunctions.net/sendEmail',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
            }
          );
        }

        // prettier-ignore
        setNotify({ isOpen: true, type: 'success', message: 'Se creó el reporte exitosamente.' });
        cleanData();
        handleNavigateToReports();
      } catch (error) {
        // prettier-ignore
        setNotify({ isOpen: true, type: 'error', message: 'No se pudo crear el reporte, intente de nuevo más tarde.' })
        console.log('there was an error uploading the file', error);
      }
    } else {
      // prettier-ignore
      setNotify({ isOpen: true, type: 'warning', message: 'Por favor revisa que los campos de cliente, servicio y valores esten llenados.'});
    }
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleMenuClick = (event, type) => {
    setMenu({ element: event.currentTarget, type: type });
  };

  const handleMenuItemClick = (type, value) => {
    switch (type) {
      case 'client':
        setClient(value);
        break;
      case 'service':
        setService(value);
        break;
    }
    handleClose();
  };

  const handleClose = () => {
    setMenu({ element: null, type: null });
  };

  useEffect(async () => {
    clients = await fetchClients();
  }, []);

  return (
    <Grid container item>
      <Typography variant="h2" className={classes.header}>
        Reporte nuevo
      </Typography>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginBottom: '5rem' }}
      >
        <Grid item></Grid>
        <Grid>
          <Button
            variant="contained"
            disableElevation
            className={classes.publishButton}
            onClick={handlePublish}
          >
            <Typography>Publicar</Typography>
          </Button>
          <Button
            variant="outlined"
            className={classes.outlinedButton}
            onClick={handleNavigateToReports}
          >
            <Typography>Cancelar</Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid container item xs={6} alignItems="center">
          <Typography variant="h6" className={classes.label}>
            Cliente
          </Typography>
          <Button
            disableElevation
            variant="outlined"
            size="large"
            endIcon={<DropdownIcon />}
            onClick={(e) => handleMenuClick(e, 'client')}
            className={`${classes.dropDown} ${classes.outlinedButton}`}
          >
            <Typography>
              {client
                ? `${client.Name} ${client.LastName}`
                : 'Selecciona un cliente'}
            </Typography>
          </Button>
        </Grid>
        <Grid container item xs={6} alignItems="center">
          <Typography variant="h6" className={classes.label}>
            Servicio
          </Typography>
          <Button
            disableElevation
            variant="outlined"
            size="large"
            endIcon={<DropdownIcon />}
            onClick={(e) => handleMenuClick(e, 'service')}
            className={`${classes.dropDown} ${classes.outlinedButton}`}
          >
            <Typography>{service}</Typography>
          </Button>
        </Grid>
        <Grid
          container
          item
          xs={6}
          alignItems="center"
          style={{ paddingTop: '15px' }}
        >
          <Typography variant="h6" className={classes.label}>
            Fecha de Creacion
          </Typography>
          <TextField
            variant="outlined"
            required
            id="endDate"
            type="date"
            placeholder="AAAA-MM-DD"
            name="docDate"
            size="small"
            InputProps={{
              inputProps: { min: '2015-01-01', max: '2100-01-01' },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              handleDateChange(e);
            }}
          />
        </Grid>
        <FileInput setFile={handleChangeFile} />
      </Grid>

      {/* Client  menu */}
      <Menu
        id="client-menu"
        anchorEl={menu.element}
        keepMounted
        open={Boolean(menu.element) && menu.type === 'client'}
        onClose={handleClose}
        PaperProps={{ style: { maxHeight: '13.5rem' } }}
      >
        {clients.map((individualClient, index) => (
          <MenuItem
            key={index}
            onClick={() => handleMenuItemClick('client', individualClient)}
          >
            {`${individualClient.Name} ${individualClient.LastName}`}
          </MenuItem>
        ))}
      </Menu>
      {/* Service  menu */}
      <Menu
        id="service-menu"
        anchorEl={menu.element}
        keepMounted
        open={Boolean(menu.element) && menu.type === 'service'}
        onClose={handleClose}
        PaperProps={{ style: { maxHeight: '13.5rem' } }}
      >
        {services.map((individualService, index) => (
          <MenuItem
            key={index}
            onClick={() => handleMenuItemClick('service', individualService)}
          >
            {individualService}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export default NewReport;
