import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Avatar, Divider } from '@material-ui/core';
import useStyles from './ContactStyles';
import messageLogo from '../../../assets/icons/Contacto_mendex.svg';
import toolsLogo from '../../../assets/icons/Herramientas_técnico.svg';
import Message from '../Messages/Message/Message';
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';

const BloqueContact = (props) => {
  const classes = props.styles;

  return (
    <Grid container item>
      <Grid item className={classes.typeContainer}>
        <Typography className={classes.type}>{props.tipo}</Typography>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item xs={9}>
          <Grid item xs={1}>
            <Avatar
              alt="icon"
              src={props.icon}
              className={classes.icon}
            ></Avatar>
          </Grid>
          <Grid container item direction="column" xs={10} className = {classes.contactContainer} >
            <Grid item>
              <Typography className={classes.subtitulo}>
                {props.subtitulo}
              </Typography>
            </Grid>
            <Grid item>
              {props.texto.map((value) => (
                <Typography className={classes.text}>{value}</Typography>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <Button onClick={props.click} className={classes.buttonAqua}>
            <Typography>{props.buttonText}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const Contact = () => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();
  const [isMessageSelected, setIsMessageSelected] = useState(false);

  const whatsappClick = () => {
    window.open('https://wa.link/ztv7o0');
  };

  const message = {
    id: '1HDLjNXHgde3p2K9JhRQ',
    user: 'Hospital Morelos',
    messageText:
      'Necesito seguimiento con el servicio de capacitación del equipo 7.',
    sentAt: 'December 17, 2021 11:30:00',
  };

  const handleMessageClick = (message) => {
    setIsMessageSelected(true);
    history.push(`${path}/${message.id}`);
  };

  const setIsMessageSelectedTrue = () => {
    setIsMessageSelected(true);
  };

  const handleNavigateToMessages = () => {
    history.push('/dashboard/mensajes');
  };

  // When navigating back from a message, set selected message to false
  useEffect(() => {
    if (location.pathname === '/dashboard/contacto') {
      setIsMessageSelected(false);
    }
  }, [location]);

  return (
    <>
      {isMessageSelected ? null : (
        <Grid container className={classes.root} direction="column">
          <Grid item className={classes.titleContainer}>
            <Typography className={classes.title}>Contacto</Typography>
          </Grid>
          <BloqueContact
            tipo={'Mensajes directos'}
            icon={toolsLogo}
            subtitulo={'Técnico Méndex'}
            texto={['Ultimo mensaje 08 enero 2021']}
            buttonText={'Ir a mensajes'}
            styles={classes}
            click={() => handleNavigateToMessages()}
          />
          <Divider variant="middle" className={classes.divider} />
          <BloqueContact
            tipo={'Llamadas'}
            icon={messageLogo}
            texto={['Teléfono 614 123 4567', 'WhatsApp 614 123 4567']}
            subtitulo={'Contacto Imageneología Mendex'}
            buttonText={'Enviar WhatsApp'}
            styles={classes}
            click={whatsappClick}
          />
          <Divider variant="middle" className={classes.divider} />
        </Grid>
      )}
      <Switch>
        <Route path={`${path}/${message.id}`}>
          <Message
            recentMessage={message}
            setIsMessageSelectedTrue={setIsMessageSelectedTrue}
          />
        </Route>
      </Switch>
    </>
  );
};

export default Contact;
