import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    paddingLeft: '3.5rem'
  },

  createTitle: {
    fontSize: '40px',
    fontWeight: 'bold',
  },

  title: {
    fontSize: '25px',
    fontWeight: 'bold',
    paddingBottom: '1rem',
  },

  subtitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    paddingBottom: '1rem',
  },

  text: {
    fontSize: '16px',
    paddingRight: '20px',
    paddingBottom: '30px',
  },

  avatar: {
    backgroundColor: '#9be4df',
    width: '150px',
    height: '150px',
    marginBottom: '15px',
  },
  iconSize: {
    width: '90px',
    height: '90px',
  },

  servicios: {
    paddingLeft: '3rem'
  },

  dataField: {
    height: 40,
    width: 350,
    backgroundColor: 'white',
    borderRadius: 6,
    marginBottom: '10px',
    [theme.breakpoints.down('1920')]: {
      width: 190,
    },
  },
  buttonAqua: {
    marginRight: '1.5rem',
    textTransform: 'none',
    padding: '10px 40px 10px 40px',
    background: '#3ec5bb',
    '&:hover': {
      backgroundColor: '#9be4df',
    },
    color: 'white',
    border: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
  },

  cancelButton: {
    textTransform: 'none',
    padding: '10px 40px 10px 40px',
    background: '#ffffff',
    '&:hover': {
      backgroundColor: '#9be4df',
    },
    color: 'black',
    border: '2px solid #3ec5bb',
    fontWeight: 'bold',
    fontSize: '16px',
  },

  buttonImg: {
    color: 'white',
    marginLeft: '26px',
    marginTop: '10px',
    textTransform: 'none',
    border: 'none',
    background: '#3ec5bb',
    '&:hover': {
      backgroundColor: '#9be4df',
    },
  },

  container: {
    marginTop: '40px',
  },
}));

export default useStyles;
