import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    margin: 0,
  },
  outlinedButton: {
    textTransform: 'none',
    border: `2px solid ${theme.palette.secondary.main}`,
    padding: '0.75rem',
    margin: '0 0.625rem',
  },
  dropDown: {
    margin: '0.3rem',
  },
  dropDownError: {
    borderColor: 'red',
  },
  publishButton: {
    textTransform: 'none',
    color: 'white',
    padding: '0.875rem 2.5rem',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  label: {
    marginRight: '3rem',
    fontWeight: 'bold',
  },
  subheader: {
    marginTop: '3.125rem',
    marginBottom: '3.125rem',
    fontWeight: 'bold',
  },
  input: {
    fontSize: '1.25rem',
    width: '10rem',
    height: '2.5rem',
    textAlign: 'center',
    '& fieldset': {
      border: `2px solid ${theme.palette.secondary.main}`,
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },
  text: {
    margin: '4.375rem 7rem 4.375rem 0',
  },
  line: {
    height: '2px',
    marginTop: '1.5rem',
    backgroundColor: 'black',
  },
}));

export default useStyles;
