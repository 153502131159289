import React, { useEffect, useState, useRef } from 'react';
import { auth, db } from '../../../../Utilities/Firebase';
import firebase from 'firebase';
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  InputAdornment,
} from '@material-ui/core';

import Notification from '../../../../Helpers/Notification';

import useStyles from './MessageStyles';

import { useAuth } from '../../../../Contexts/AuthContext';

// Icons
import Adjuntar from '../../../../assets/icons/Adjuntar.svg';
import Documentos from '../../../../assets/icons/Documentos.svg';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useParams, useLocation } from 'react-router-dom';

const IndividualMessage = (props) => {
  const { text, uid, photoURL, receiver, chat, createdAt } = props.message;
  const classes = useStyles();

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

  let currentDate = new Date(
    createdAt && createdAt.toDate()
  ).toLocaleDateString();

  let currentDateTemp = new Date(createdAt && createdAt.toDate());

  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justifyContent={messageClass === 'sent' ? 'flex-end' : 'flex-start'}
      alignItems="flex-start"
      className={classes.individualMessage}
    >
      <Avatar src={photoURL} className={classes.messageAvatar} />
      <Grid item xs={9}>
        <Typography className={classes.messageStyle}>{text}</Typography>
        <Typography className={classes.messageHour}>
          {currentDateTemp.getMinutes()}
        </Typography>
        <Typography className={classes.messageHour}>
          {currentDateTemp.getHours()}:
        </Typography>
        <Typography className={classes.messageDate}>{currentDate}</Typography>
      </Grid>
    </Grid>
  );
};

const Message = (props) => {
  const classes = useStyles();

  const location = useLocation();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  const { recentMessage, userPic } = props;

  const { currentUser } = useAuth();

  const currentUserId = currentUser ? currentUser.uid : null;

  const currentUserRef = db.collection('UserData').doc(currentUserId);

  const [mRes, setMRes] = useState();

  const [formValue, setFormValue] = useState('');

  const [chatter, setChatter] = useState();

  const [picture, setPicture] = useState();

  const [converArray, setConverArray] = useState([]);

  const messagesRef = db.collection('messages');

  const userRef = db.collection('UserData');

  const query = messagesRef.orderBy('createdAt', 'desc').limit(10000);

  const [admin, setAdmin] = useState();

  const [messages] = useCollectionData(query, { idField: 'id' });

  const { id, user, messageText, sentAt } = recentMessage;

  const el = useRef(null);
  
  const [count,setCount] = useState(0)

  useEffect(() => {
    el.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    
  },[count]);

  useEffect(() => {
    const loc = window.location.href;

    const receiverId = loc.substr(43);
    

    setMRes(receiverId);

    userRef
      .doc(currentUser.uid)
      .get()
      .then((doc) => {
        if (doc.data().AdminUser == false) {
          setAdmin(false);
          messagesRef
            .where('chat', '==', currentUser.uid)
            .orderBy('createdAt')
            .limit(10000)
            .onSnapshot((querySnapshot) => {
              const mensajes = [];
              querySnapshot.forEach((doc) => {
                mensajes.push({ id: doc.id, ...doc.data() });
              });
              setConverArray(mensajes);
            });
        } else {
          setAdmin(true);
          messagesRef
            .where('chat', '==', receiverId)
            .orderBy('createdAt')
            .limit(10000)
            .onSnapshot((querySnapshot) => {
              const mensajes = [];
              querySnapshot.forEach((doc) => {
                mensajes.push({ id: doc.id, ...doc.data() });
              });
              setConverArray(mensajes);
            });
        }
      });
      
      if(count<4){
        setCount(count+1)
      }

  }, [converArray]);

  const prevChatter = userRef.doc(mRes);

  prevChatter.get().then((doc) => {
    if (doc.exists) {
      setChatter(doc.data().Institution);
      setPicture(doc.data().ProfilePic);
    } else {
      console.log('No such document!');
    }
  });

  const sendMessage = async (e) => {
    e.preventDefault();

    currentUserRef.get().then((doc) => {
      if(!doc.data().isChatting){
        currentUserRef.update({
          isChatting: true,
        });
      };
    });

    const { uid } = auth.currentUser;

    if (admin) {
      if (formValue) {
        await messagesRef.add({
          text: formValue,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid,
          photoURL: userPic,
          receiver: mRes,
          chat: mRes,
        });
      } else {
        setNotify({
          isOpen: true,
          message: 'Mensaje vacío',
          type: 'error',
        });
      }

      setFormValue('');
    } else {
      if (formValue) {
        await messagesRef.add({
          text: formValue,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          uid,
          photoURL: userPic,
          receiver: currentUser.uid,
          chat: currentUser.uid,
        });
      } else {
        setNotify({
          isOpen: true,
          message: 'Mensaje vacío',
          type: 'error',
        });
      }

      setFormValue('');
    }
  };

  const scrollToBottom = () => {
    el.current?.scrollIntoView({ behavior: "smooth" })
  };

  useEffect(() => {
    scrollToBottom()
  }, [messages]);

  return (
    <Grid container>
      <Grid container item xs={12} direction="row" className={classes.header}>
        <Grid item xs={1}>
          <Avatar src={picture} className={classes.avatar} />
        </Grid>
        <Grid
          container
          item
          xs={10}
          direction="column"
          justifyContent="space-evenly"
        >
          <Typography variant="h6">{user}</Typography>
          <Typography className={classes.institution}>{chatter}</Typography>
        </Grid>
        <Grid container item xs={1} justifyContent="center" alignItems="center">
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        className={classes.messagesContainer}
      >
        <Grid
          container
          justifyContent="space-evenly"
          style={{ maxHeight: 400, overflow: 'auto' }}
        >
          {converArray &&
            converArray.map((msg) => (
              <IndividualMessage key={msg.id} message={msg} />
            ))}
          <div id={'el'} ref={el}></div>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          className={classes.inputBarContainer}
        >
          <Grid item xs={10}>
            <TextField
              value={formValue}
              onChange={(e) => setFormValue(e.target.value)}
              variant="outlined"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  sendMessage(e);
                }
              }}
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={Adjuntar}></img>
                  </InputAdornment>
                ),
              }}
              className={classes.inputBar}
            />
          </Grid>
          <Button
            onClick={sendMessage}
            variant="contained"
            color="primary"
            className={classes.sendButton}
          >
            Enviar
          </Button>
        </Grid>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </Grid>
  );
};

export default Message;
