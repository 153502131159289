import React, { useEffect, useState } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  Grid,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useStyles from './TableStyles';
import Notification from '../../Helpers/Notification';
import { deleteReport } from '../../Utilities/Report/Report';
import ModalComponent from '../../Helpers/Modal/Modal';

const TableHeader = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  classes,
  headerCells,
  tableType,
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" className={classes.tableHeaderCell}>
          {tableType === 'reportes' ? (
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
              className={classes.checkbox}
            />
          ) : null}
        </TableCell>
        {headerCells.map((headerCell) => (
          <TableCell
            key={headerCell.id}
            align={headerCell.numeric ? 'right' : 'left'}
            padding={headerCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headerCell.id ? order : false}
            className={
              tableType === 'reportes'
                ? classes.tableHeaderCell
                : classes.tableHeaderCellClient
            }
          >
            <TableSortLabel
              active={orderBy === headerCell.id}
              direction={orderBy === headerCell.id ? order : 'asc'}
              onClick={createSortHandler(headerCell.id)}
            >
              {headerCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const CustomTable = ({
  headerCells,
  info,
  defaultOrderBy,
  handleCellClick,
  userData,
  setIsEditing,
  setIsDocActive,
  tableType,
}) => {
  const [modal, setModal] = useState({ isOpen: false, report: null });
  const [element, setElement] = useState(null);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [selected, setSelected] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    type: 'info',
    message: '',
  });
  const classes = useStyles();
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleModalClose = () => {
    setModal({ isOpen: false, report: null });
  };

  const handleMenuClick = (event) => {
    setElement(event.currentTarget);
  };

  const handleClose = () => {
    setElement(null);
  };

  const handleExport = (row) => {
    if (row.fileUrl) {
      const link = document.createElement('a');
      link.setAttribute('href', row.fileUrl);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      setNotify({
        isOpen: true,
        type: 'error',
        message: 'No se pudo descargar el archivo.',
      });
    }
    handleClose();
  };

  const handleEdit = (row) => {
    history.push(`${path}?r=${row.id}`);
    setIsDocActive(true);
    setIsEditing(true);
    handleClose();
  };

  const handleDelete = async () => {
    try {
      await deleteReport(modal.report.id);
      handleModalClose();
    } catch (e) {
      console.error(e);
    }
  };

  // Decide whether desc or asc sorting based on property
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = info.map((doc) => doc.invoice);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleCheckboxClick = (event, invoice) => {
    const selectedIndex = selected.indexOf(invoice);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, invoice);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const ord = comparator(a[0], b[0]);
      if (ord !== 0) return ord;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const descendingComparator = (a, b, ordrBy) => {
    if (ordrBy === 'date') {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
    if (b[ordrBy] < a[ordrBy]) {
      return -1;
    }
    if (b[ordrBy] > a[ordrBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (ord, ordrBy) => {
    return ord === 'desc'
      ? (a, b) => descendingComparator(a, b, ordrBy)
      : (a, b) => -descendingComparator(a, b, ordrBy);
  };

  const isSelected = (invoice) => selected.indexOf(invoice) !== -1;

  const ModalBody = (
    <Grid container justifyContent="center">
      <Typography
        variant="h3"
        style={{ fontSize: '2.5rem', fontWeight: 'bold', marginTop: '2rem' }}
      >
        ¿Estás seguro?
      </Typography>
      <Typography style={{ fontSize: '1.25rem', marginTop: '4rem' }}>
        Esto eliminará de forma <strong>permanente</strong> el reporte y no
        habrá forma de recuperarlo.
      </Typography>
      <Grid
        container
        style={{ marginTop: '4rem' }}
        justifyContent="space-evenly"
      >
        <Button
          onClick={handleModalClose}
          color="primary"
          variant="outlined"
          style={{
            padding: '0.5rem 2rem',
            fontSize: '1.5rem',
            textTransform: 'none',
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleDelete}
          variant="contained"
          style={{
            padding: '0.5rem 2rem',
            fontSize: '1.5rem',
            textTransform: 'none',
            backgroundColor: '#f75f54',
            color: 'white',
          }}
        >
          Eliminar
        </Button>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    setOrderBy(defaultOrderBy);
  }, [defaultOrderBy]);

  return (
    <>
      {notify.isOpen && <Notification notify={notify} setNotify={setNotify} />}
      <TableContainer style={{ boxSizing: 'border-box' }}>
        <Table className={classes.table}>
          <TableHeader
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={info.length}
            headerCells={headerCells}
            classes={classes}
            tableType={tableType}
          />
          <TableBody>
            {stableSort(info, getComparator(order, orderBy)).map(
              (row, index) => {
                const isItemSelected = isSelected(row.invoice);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.invoice}
                    selected={isItemSelected}
                    className={classes.tableRow}
                  >
                    {tableType === 'reportes' ? (
                      <>
                        <TableCell
                          padding="checkbox"
                          className={`${classes.tableCell} ${classes.leftTableCell}`}
                          onClick={(event) =>
                            handleCheckboxClick(event, row.invoice)
                          }
                        >
                          <Checkbox
                            checked={isItemSelected}
                            className={classes.checkbox}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className={classes.tableCell}
                          onClick={() => handleCellClick(row)}
                        >
                          {row.invoice}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          onClick={() => handleCellClick(row)}
                        >
                          {row.createdBy}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          onClick={() => handleCellClick(row)}
                        >
                          {row.service}
                        </TableCell>
                        <TableCell
                          className={classes.tableCell}
                          onClick={() => handleCellClick(row)}
                        >
                          {new Date(row.createdAt).toLocaleDateString()}
                        </TableCell>
                        <TableCell
                          className={`${classes.tableCell} ${classes.rightTableCell}`}
                        >
                          <Button
                            id={`menu-btn-${row.invoice}`}
                            onClick={handleMenuClick}
                          >
                            <MoreHorizIcon />
                          </Button>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                          className={`${classes.tableCellClients} ${classes.leftTableCell}`}
                          onClick={() => handleCellClick(row)}
                        ></TableCell>
                        <TableCell
                          className={classes.tableCellClients}
                          onClick={() => handleCellClick(row)}
                        >
                          {row.Name + ' ' + row.LastName}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellClients}
                          onClick={() => handleCellClick(row)}
                        >
                          {row.Institution}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellClients}
                          onClick={() => handleCellClick(row)}
                        >
                          {row.Phone}
                        </TableCell>
                        <TableCell
                          style={{ paddingRight: '100px' }}
                          className={`${classes.tableCellClients} ${classes.rightTableCell}`}
                          onClick={() => handleCellClick(row)}
                        >
                          {row.Email}
                        </TableCell>
                      </>
                    )}
                    <Menu
                      id={`month-menu-${row.invoice}`}
                      anchorEl={element}
                      keepMounted
                      open={
                        Boolean(element) &&
                        element.id === `menu-btn-${row.invoice}`
                      }
                      onClose={handleClose}
                      PaperProps={{ style: { maxHeight: '13.5rem' } }}
                    >
                      {userData.adminUser ? (
                        <>
                          <MenuItem onClick={() => handleEdit(row)}>
                            Editar
                          </MenuItem>
                          <MenuItem
                            onClick={() =>
                              setModal({ isOpen: true, report: row })
                            }
                          >
                            Eliminar
                          </MenuItem>
                        </>
                      ) : null}
                      <MenuItem onClick={() => handleExport(row)}>
                        Exportar
                      </MenuItem>
                    </Menu>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalComponent
        body={ModalBody}
        handleModal={handleModalClose}
        open={modal.isOpen}
      />
    </>
  );
};

export default CustomTable;
