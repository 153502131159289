import React, { useState, useRef } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import useStyles from './FileInputStyles';

const FileInput = ({ setFile }) => {
  const [fileName, setFileName] = useState('No se eligió archivo');
  const inputRef = useRef();
  const classes = useStyles();

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    setFile(file);
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className={classes.container}
    >
      <input
        id="input"
        type="file"
        accept="application/pdf, application/msword"
        ref={inputRef}
        onChange={handleFileChange}
        className={classes.input}
      />
      <Button
        disableElevation
        size="large"
        variant="outlined"
        onClick={handleClick}
        className={classes.button}
      >
        <Typography>Selecciona un archivo</Typography>
      </Button>
      <Typography className={classes.fileName}>{fileName}</Typography>
    </Grid>
  );
};

export default FileInput;
