import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { Grid } from '@material-ui/core';
import useStyles from './ModalStyles';

const Body = ({ body }) => {
  const classes = useStyles();

  const getModalStyle = () => {
    return {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    };
  };

  const [modalStyle] = useState(getModalStyle);

  return (
    <Grid style={modalStyle} className={classes.paper}>
      {body}
    </Grid>
  );
};

const ModalComponent = ({ body, handleModal, open }) => {
  return (
    <Grid>
      <Modal
        open={open}
        onClose={handleModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Body body={body} />
      </Modal>
    </Grid>
  );
};

export default ModalComponent;
