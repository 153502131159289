import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '2.5rem',
    margin: 0,
    padding: '4rem 0 0 1rem',
  },
  dropDown: {
    textTransform: 'none',
    border: `2px solid ${theme.palette.secondary.main}`,
    padding: '0.75rem',
    margin: '0.3rem',
  },

  button: {
    textTransform: 'none',
    color: 'white',
    backgroundColor: `${theme.palette.primary.main}`,
    padding: '0 2.75rem',
    margin: '0.3rem',
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.main}`,
    },
  },
  downloadButton: {
    height: '3.3rem',
    margin: '0.3rem',
    backgroundColor: '#e5e5e5',
  },
}));

export default useStyles;
