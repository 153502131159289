import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  navBar: {
    justifyContent: 'space-between',
    padding: '40px 45px 40px 45px',
    alignItems: 'center',
  },
  navBarButton: {
    textTransform: 'none',
    padding: '8px 11px',
    fontFamily: 'Montserrat',
    fontSize: '20px',
    cursor: 'pointer',
    lineHeight: 1.75,
    justifyContent: 'center',
  },
  loginButton: {
    color: 'white',
  },
  logo: {
    width: '220px',
    height: '95px',
  },
});

export default useStyles;
