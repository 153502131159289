import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
  },
  subheader: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  },
  notificationsSection: {
    marginBottom: '2.625rem',
  },
  notificationContainer: {
    height: '2.75rem',
    padding: '0.6rem 1rem',
    marginBottom: '0.75rem',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    backgroundColor: "#E2FBF9"
  },
  seenNotificationContainer: {
    height: '2.75rem',
    padding: '0.6rem 1rem',
    marginBottom: '0.75rem',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
  },
  star: {
    margin: 0,
    marginRight: '0.5rem',
    padding: 0,
  },
  dropDown: {
    textTransform: 'none',
    border: `2px solid ${theme.palette.secondary.main}`,
    padding: '0.75rem',
    margin: '0 0.3rem 1.25rem',
  },
  hour: {
    fontSize: '0.75rem',
    opacity: 0.6,
  },
  buttonAqua: {
    marginRight: '1rem',
    textTransform: 'none',
    margin: '0 0.3rem 1.25rem',
    background: '#3ec5bb',
    '&:hover': {
      backgroundColor: '#9be4df',
    },
    color: 'white',
    border: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  link:{
    textDecoration:'none', 
    color:'#3ec5bb',
    '&:hover': {
        color: '#9be4df',
      },
    cursor:"pointer",
}
}));

export default useStyles;
