import { makeStyles } from '@material-ui/core/styles';
import { BorderBottom } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 500,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    flexGrow: 0,
    flexShrink: 0,
    minHeight:'100vh'
  },
  username: {
    textDecoration: 'none',
    fontWeight: 500,
    '&:visited': {
      color: 'black',
    },
  },
  perfilPic: {
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),
    border: 'solid 4px #FFFFFF',
  },

  userSelected: {
    width: theme.spacing(7.5),
    height: theme.spacing(7.5),
    backgroundColor: '#9be4df',
    border: 'solid 4px #9be4df',
  },

  button: {
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
    marginRight: '1.3rem',
    minHeight: '64px',
    minWidth: '64px',
  },
  buttonSelected: {
    border: 'solid 4px #9be4df',
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
    marginRight: '1.3rem',
    minHeight: '64px',
    minWidth: '64px',
  },
  barContainer: {
    justifyContent: 'flex-end',
    flexGrow: 0,
    maxWidth: '41.666667%',
    flexBasis: 'none',
    marginLeft: '6rem',
    [theme.breakpoints.down('1280')]: {
      margin: 'auto',
    },
  },
  appbar: {
    padding: '1.2rem',
    minHeight: '103px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  appbarRi: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(7),
  },
  logo: {
    minWidth: '205px',
    maxWidth: '205px',
  },
  logoMendex: {
    minWidth: '140px',
    minHeight: '61px',
    paddingLeft: '20px',
  },
  name: {
    paddingLeft: '1rem',
    marginTop: '1rem',
  },
  divider: {
    minHeight: 'inherit',
    minHeight: '54px',
    width: '3px',
    backgroundColor: `${theme.palette.secondary.main}`,
    borderRadius: 50,
  },
  tab: {
    minHeight: '74px',
    textTransform: 'none',
    minWidth: '223px',
  },
  tabSelected: {
    minHeight: '74px',
    textTransform: 'none',
    minWidth: '223px',
    boxShadow: '3px 3px 4px 0 #eeecf1',
    border: 'solid 1px #eeecf1',
    borderRadius: '5px',
  },
  normal: {
    fontWeight: 400,
  },
  selected: {
    fontWeight: 700,
  },

  indicator: {
    minHeight: '74px',
    width: '12px',
    borderRadius: 6,
  },

  emptyindicator: {
    minHeight: '74px',
    width: '12px',
    borderRadius: 6,
    backgroundColor: '#FFFFFF',
  },

  logOutButton: {
    cursor: 'pointer',
    position: 'absolute',
    bottom: 0,
    padding: '25px',
  },
}));

export default useStyles;
