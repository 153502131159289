import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { Star, StarBorder } from '@material-ui/icons';
import {updateSeenNotification} from "../../../../Utilities/Notification/Notification"

export const ReportNotification = ({
  index,
  notification,
  toggleFav,
  classes,
  setIsOpen
}) => {

  const handleSeenNotification = () =>{
    updateSeenNotification(notification.id);
  }

  const handleSeenArray = (notify) =>{
      if (!notify.isOpen) {
        setIsOpen(false);
      }
  }

  const getTextWithLink = (text) => {
    const result = text.match(/reporte [0-9]{1,}/);
    if (result) {
      const normalText = text.substring(0, result.index);
      const linkText = result[0];
      return (
        <Typography>
          {normalText}
          <Link to={`/dashboard/reportes?r=${notification.objectId}`} onClick={()=>{handleSeenNotification()}} className={classes.link} >
            {linkText}
          </Link>
        </Typography>
      );
    } else {
      return <Typography>text</Typography>;
    }
  };

  return (
    <Grid
      key={index}
      container
      item
      xs={12}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={notification.isOpen? classes.seenNotificationContainer:classes.notificationContainer}
    >
      <Grid container item xs={11}>
        <IconButton
          size="small"
          onClick={() => toggleFav(notification.id)}
          className={classes.star}
        >
          {notification.isFav ? (
            <Star color="primary" />
          ) : (
            <StarBorder color="primary" />
          )}
        </IconButton>
        {handleSeenArray(notification)}
        {getTextWithLink(notification.text)}
      </Grid>
      <Grid item xs={1}>
        <Typography className={classes.hour}>
          {new Date(notification.createdAt).toLocaleTimeString('es-MX', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          })}
        </Typography>
      </Grid>
    </Grid>
  );
};
