import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Grid, Typography, Button, Menu, MenuItem } from '@material-ui/core';
import { auth, db } from '../../../Utilities/Firebase';
import { useAuth } from '../../../Contexts/AuthContext';
import useStyles from './MessagesStyles';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Notification from '../../../Helpers/Notification';

// Components
import Message from './Message/Message';

const Messages = ({ data, setUserData }) => {
  const [messagesArray, setMessagesArray] = useState([]);
  const [element, setElement] = useState(null);
  const [lastMessage, setLastMessage] = useState([]);
  const [isMessageSelected, setIsMessageSelected] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const mounted = useRef(false);
  const messageSelected = useRef(null);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();
  const { currentUser } = useAuth();
  const currentUserId = currentUser ? currentUser.uid : null;

  const userRef = db.collection('UserData');
  const messagesRef = db.collection('messages');

  const getTimeFromDate = (date) => {
    if (typeof date == 'string') {
      date = new Date(date);
    }

    return date.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  const handleMessageClick = (message) => {
    history.push(`${path}?m=${message.id}`);
  };

  const handleMenuClick = (event) => {
    setElement(event.currentTarget);
  };

  const handleClose = () => {
    setElement(null);
  };


  const deleteChat = (chat, index) => {
    const messageQuery = messagesRef.where('receiver', '==', chat);

    messageQuery
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
        userRef.doc(chat).update({ isChatting: false });
      })
      .then(() => {
        setNotify({
          isOpen: true,
          message: 'Chat eliminado correctamente',
          type: 'success',
        });
      })
      .catch(() => {
        setNotify({
          isOpen: true,
          message: 'Hubo un error intentalo mas tarde',
          type: 'error',
        });
      });
  };

  const getLastMessages = () => {
    messagesArray && messagesArray.map((message, index) => {
      const mensajes = [];
      const compareTo = data.adminUser ? message.id : currentUser.uid;

      messagesRef.where('chat','==', compareTo).onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // Convert firebase timestamp to js date
          const data = doc.data();
          if (data.createdAt) {
            const date = data.createdAt.toDate();
            data.createdAt = date;
          }
          mensajes.push(data);
        })
        // Sort array by date, newest first
        mensajes.sort((a, b) => b.createdAt && a.createdAt ? b.createdAt - a.createdAt : 1)

        // Store first (newest) message in state
        setLastMessage((prevMessages) => {
          const newMessages = [...prevMessages]
          newMessages.push(mensajes[0])

          return newMessages
        })
      })
    });
  };

  const getChats = () => {
    // if the email is empty, means that data is a placeholder
    if (data.email) {
      userRef.where('isChatting', '==', true).where('AdminUser', '==', !data.adminUser).onSnapshot((querySnapshot) => {
        const chats = [];
        querySnapshot.forEach((doc) => {
          chats.push({id: doc.id, ...doc.data()});
        })
        setMessagesArray(chats);
      })
    }
  };

  // When navigating back from a message, set selected message to false
  useEffect(() => {
    const query = new URLSearchParams(location.search).get('m');
    const message = messagesArray.filter((msg) => msg.id === query)[0];

    if (query && message) {
      messageSelected.current = message;
      setIsMessageSelected(true);
    } else {
      setIsMessageSelected(false);
    }
    getChats();
  }, [location]);
  
  useEffect(() => {
    if (messagesArray.length) {
      getLastMessages();
    }
  }, [messagesArray]);

  useEffect(() => {
    if (mounted.current) {
      getChats();
    }
  }, [data])
  
  useEffect(() => {
    mounted.current = true;
  }, []);

  return isMessageSelected ? (
    <Message
      recentMessage={messageSelected.current}
      userPic={data.profilePic}
      setUserInfo={setUserData}
    />
  ) : (
    <Grid container direction="column">
      <Typography variant="h1" className={classes.header}>
        Mensajes
      </Typography>
      <Typography variant="h2" className={classes.subheader}>
        Mensajes directos
      </Typography>
      {messagesArray.map((message, index) => {
        return (
          <Grid
            key={message.id}
            container
            className={classes.messageContainer}
          >
            <Avatar className={classes.avatar} src={message.ProfilePic} onClick={() => handleMessageClick(message)}></Avatar>
            <Grid
              container
              item
              xs={9}
              direction="column"
              justifyContent="center"
              onClick={() => handleMessageClick(message)}
            >
              <Typography variant="h6">{message.Institution}</Typography>
              <Typography className={classes.messageText}>
                {lastMessage[index] && lastMessage[index].text}
              </Typography>
            </Grid>
            <Typography className={classes.time} onClick={() => handleMessageClick(message)}>
              {lastMessage[index] && getTimeFromDate(lastMessage[index].createdAt)}
            </Typography>
            <Button id={`menu-btn-${message.id}`} onClick={handleMenuClick}>
              <MoreHorizIcon />
            </Button>
            <Menu
              id={`basic-menu-${message.id}`}
              anchorEl={element}
              keepMounted
              open={Boolean(element) && element.id === `menu-btn-${message.id}`}
              onClose={handleClose}
              PaperProps={{ style: { maxHeight: '13.5rem' } }}
            >
              <MenuItem
                onClick={() => {
                  deleteChat(message.id);
                }}
              >
                Eliminar chat
              </MenuItem>
            </Menu>
          </Grid>
        );
      })}
      <Notification notify={notify} setNotify={setNotify} />
    </Grid>
  );
};

export default Messages;


