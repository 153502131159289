import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: '2.5rem',
    margin: 0,
    padding: '2rem 0 0 4.325rem',
  },

  button: {
    textTransform: 'none',
    color: 'white',
    backgroundColor: `${theme.palette.primary.main}`,
    padding: '1rem 2.75rem',
    margin: '0.3rem',
    '&:hover': {
      backgroundColor: `${theme.palette.secondary.main}`,
    },
  },
}));

export default useStyles;
