import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '2rem',
  },
  label: {
    marginRight: '3rem',
    fontWeight: 'bold',
  },
  input: {
    display: 'none',
  },
  button: {
    textTransform: 'none',
    border: `2px solid ${theme.palette.secondary.main}`,
    padding: '0.75rem',
    marginRight: '0.625rem',
  },
}));

export default useStyles;
