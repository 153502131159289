import React, { useEffect, useRef, useState } from 'react';
import { Grid, Button, Typography, Menu, MenuItem } from '@material-ui/core';
import useStyles from './ReportsStyles';

import Dropdown from '../../../assets/icons/Dropdown (1).svg';
import Download from '../../../assets/icons/Descarga.svg';

import NewReport from '../Reports/NewReport/NewReport';
import SearchBar from '../../../Shared/SearchBar/SearchBar';
import Table from '../../../Shared/Table/Table';
import Notification from '../../../Helpers/Notification';
import {
  fetchReportsCollection,
  listenReportsCollection,
} from '../../../Utilities/Report/Report';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import IndividualReport from './IndividualReport/IndividualReport';

const orderBys = [
  { label: 'Por fecha', id: 'date' },
  { label: 'Por cliente', id: 'client' },
  { label: 'Por servicio', id: 'service' },
  { label: 'Por folio', id: 'invoice' },
];

// prettier-ignore
const months = [
  { label: 'Enero', number: 1 },
  { label: 'Febrero', number: 2 },
  { label: 'Marzo', number: 3 },
  { label: 'Abril', number: 4 },
  { label: 'Mayo', number: 5 },
  { label: 'Junio', number: 6 },
  { label: 'Julio', number: 7 },
  { label: 'Agosto', number: 8 },
  { label: 'Septiembre', number: 9 },
  { label: 'Octubre', number: 10 },
  { label: 'Noviembre', number: 11 },
  { label: 'Diciembre', number: 12 },
];

const tableHeaderCells = [
  { id: 'invoice', disablePadding: true, label: 'Folio' },
  { id: 'client', disablePadding: false, label: 'Cliente' },
  { id: 'service', disablePadding: false, label: 'Servicio' },
  { id: 'date', disablePadding: false, label: 'Fecha' },
];

let years = [];
const getYears = () => {
  years = [];
  const year = new Date().getFullYear();
  const pastYears = Array.from(new Array(5), (val, index) => year - index - 1);
  const futureYears = Array.from(new Array(5), (val, index) => index + year);
  pastYears.map((pastYear) => years.push({ label: pastYear, value: pastYear }));
  futureYears.map((futureYear) =>
    years.push({ label: futureYear, value: futureYear })
  );
  years.sort((a, b) => a.value - b.value);
  years.reverse();
};

const DropdownIcon = () => (
  <img src={Dropdown} style={{ padding: '0 0.25rem' }}></img>
);

const DownloadIcon = () => <img src={Download}></img>;

const Reports = ({ userData, clients }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [menu, setMenu] = useState({ element: null, type: null });
  const [orderBy, setOrderBy] = useState({ label: 'Por fecha', id: 'date' });
  const [month, setMonth] = useState({ label: 'Todos los meses', number: -1 });
  const [year, setYear] = useState({ label: '2021', value: 2021 });
  const [filteredDocs, setFilteredDocs] = useState([]);
  const [isDocActive, setIsDocActive] = useState(false);
  const [isNewActive, setIsNewActive] = useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    type: 'info',
    message: '',
  });
  const docs = useRef([]);
  const activeDocId = useRef('');
  const mounted = useRef(false);
  const location = useLocation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const classes = useStyles();

  const handleNavigateToNewReport = () => {
    history.push(`${path}/nuevo`);
    setIsNewActive(true);
  };

  const handleUpdateSearchTerm = (newSearchTerm) => {
    setSearchTerm(newSearchTerm);
  };

  const handleFilterItems = () => {
    setFilteredDocs((prevFilteredDocs) =>
      prevFilteredDocs.filter((doc) => {
        return Object.keys(doc).some((property) => {
          // Only check if these properties match the searchTerm
          if (
            property === 'client' ||
            property === 'service' ||
            property === 'invoice' ||
            property === 'machine'
          ) {
            if (doc[property] && typeof doc[property] === 'string') {
              return doc[property]
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
            }
          }

          if (doc[property] && property === 'createdAt') {
            const dateString = new Date(doc[property]).toLocaleDateString();
            return dateString.includes(searchTerm);
          }

          return false;
        });
      })
    );
  };

  const handleMenuClick = (event, type) => {
    setMenu({ element: event.currentTarget, type: type });
  };

  const handleMenuItemClick = (type, value) => {
    switch (type) {
      case 'orderBy':
        setOrderBy(value);
        break;
      case 'month':
        setMonth(value);
        break;
      case 'year':
        setYear(value);
        break;
    }
    handleMenuClose();
  };

  const handleMenuClose = () => {
    setMenu({ element: null, type: null });
  };

  const handleCellClick = (doc) => {
    if (path === '/dashboard/clientes') {
      history.push(`/dashboard/reportes?r=${doc.id}`);
    } else {
      history.push(`${path}?r=${doc.id}`);
    }
  };

  const filterDocsByUserId = () => {
    let newDocs = [];
    docs.current.map((doc) => {
      if (doc.clientId === userData.id) {
        newDocs.push(doc);
      }
    });
    setFilteredDocs(newDocs);
    docs.current = newDocs;
  };

  // useEffect used to update the docs based on the input from the search bar
  useEffect(() => {
    if (!searchTerm) {
      setFilteredDocs(docs.current);
    } else {
      handleFilterItems();
    }
  }, [searchTerm]);

  // useEffect used to update the docs based on the month or year filters
  useEffect(() => {
    if (month.number > 0) {
      setFilteredDocs(() =>
        docs.current.filter((doc) => {
          const docMonth = new Date(doc.createdAt).getMonth();

          return docMonth == month.number - 1;
        })
      );
    }
  }, [month]);

  useEffect(() => {
    setFilteredDocs(() =>
      docs.current.filter((doc) => {
        const docYear = new Date(doc.createdAt).getFullYear();

        return docYear == year.value;
      })
    );
  }, [year]);

  useEffect(() => {
    const query = new URLSearchParams(location.search).get('r');
    if (query) {
      activeDocId.current = query;
      setIsDocActive(true);
    } else {
      setIsDocActive(false);
    }
  }, [location]);

  useEffect(async () => {
    if (mounted.current) {
      const fetchedDocs = await fetchReportsCollection();
      let newDocs = [...docs.current];
      fetchedDocs.map((doc) => {
        if (!newDocs.some((currentDoc) => doc.id == currentDoc.id)) {
          newDocs.push(doc);
        }
      });
      docs.current = newDocs;
      setFilteredDocs(newDocs);

      if (!userData.adminUser) {
        filterDocsByUserId();
      }
    }
  }, [userData]);

  useEffect(() => {
    getYears();

    // If not already subscribed, listen for Reports collection changes and setDocs based on that
    let unsubscribe;
    if (!mounted.current) {
      unsubscribe = listenReportsCollection((snapshot) => {
        const changes = snapshot.docChanges();
        let docArray = [];
        changes.map((change) => {
          if (change.type === 'added') {
            // If adminUser == true, then display all docs, else only display those that are for that client
            if (userData.adminUser) {
              const newDocs = [...docs.current];
              const newDoc = { id: change.doc.id, ...change.doc.data() };
              newDocs.push(newDoc);

              // Since searchTerm is empty, set filteredDocs equal to docs
              setFilteredDocs(newDocs);

              docs.current = newDocs;
            } else {
              const newDoc = { id: change.doc.id, ...change.doc.data() };
              docArray.push(newDoc);
            }
          }

          if (change.type === 'removed') {
            setFilteredDocs((prevFilteredDocs) =>
              prevFilteredDocs.filter((doc) => doc.id !== change.doc.id)
            );
            docs.current = docs.current.filter(
              (doc) => doc.id !== change.doc.id
            );
          }
        });

        if (docArray.length > 0) {
          docs.current = docArray;
          filterDocsByUserId();
        }
      });
    }
    mounted.current = true;
    // Return a ref of unsubscribe function so when component unmounts, it unsubscribes the listening for changes in the collection
    return unsubscribe;
  }, []);

  return (
    <>
      {isDocActive ? (
        <IndividualReport
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          setIsDocActive={setIsDocActive}
          docId={activeDocId.current}
          userData={userData}
        />
      ) : null}
      {isNewActive || isDocActive ? null : (
        <Grid container direction="column">
          {notify.isOpen && (
            <Notification
              notify={notify}
              setNotify={setNotify}
              position="top"
            />
          )}
          {clients ? null : (
            <SearchBar
              updateSearchTerm={handleUpdateSearchTerm}
              label={'Buscar Documentos'}
            />
          )}
          <Typography className={classes.header}>
            <strong>Reportes</strong>
          </Typography>
          <Grid container justifyContent="flex-end">
            <Button
              disableElevation
              variant="outlined"
              size="large"
              endIcon={<DropdownIcon />}
              onClick={(e) => handleMenuClick(e, 'orderBy')}
              className={classes.dropDown}
            >
              <Typography>{orderBy.label}</Typography>
            </Button>
            <Button
              disableElevation
              variant="outlined"
              size="large"
              endIcon={<DropdownIcon />}
              onClick={(e) => handleMenuClick(e, 'month')}
              className={classes.dropDown}
            >
              <Typography>{month.label}</Typography>
            </Button>
            <Button
              disableElevation
              variant="outlined"
              size="large"
              endIcon={<DropdownIcon />}
              onClick={(e) => handleMenuClick(e, 'year')}
              className={classes.dropDown}
            >
              <Typography>{year.label}</Typography>
            </Button>
            {userData.adminUser ? (
              <Button
                disableElevation
                variant="contained"
                size="medium"
                className={classes.button}
                onClick={handleNavigateToNewReport}
              >
                <Typography>
                  <strong>+ Nuevo</strong>
                </Typography>
              </Button>
            ) : null}
            <Button className={classes.downloadButton}>
              <DownloadIcon />
            </Button>
          </Grid>
          <Table
            headerCells={tableHeaderCells}
            info={filteredDocs}
            defaultOrderBy={orderBy.id}
            handleCellClick={handleCellClick}
            userData={userData}
            setIsEditing={setIsEditing}
            setIsDocActive={setIsDocActive}
            tableType={'reportes'}
          />

          {/* Order by  menu */}
          <Menu
            id="orderBy-menu"
            anchorEl={menu.element}
            keepMounted
            open={Boolean(menu.element) && menu.type === 'orderBy'}
            onClose={handleMenuClose}
          >
            {orderBys.map((orderByOption, index) => (
              <MenuItem
                key={index}
                onClick={() => handleMenuItemClick('orderBy', orderByOption)}
              >
                {orderByOption.label}
              </MenuItem>
            ))}
          </Menu>

          {/* Month  menu */}
          <Menu
            id="month-menu"
            anchorEl={menu.element}
            keepMounted
            open={Boolean(menu.element) && menu.type === 'month'}
            onClose={handleMenuClose}
            PaperProps={{ style: { maxHeight: '13.5rem' } }}
          >
            {months.map((individualMonth) => (
              <MenuItem
                key={individualMonth.number}
                onClick={() => handleMenuItemClick('month', individualMonth)}
              >
                {individualMonth.label}
              </MenuItem>
            ))}
          </Menu>

          {/* Year  menu */}
          <Menu
            id="year-menu"
            anchorEl={menu.element}
            keepMounted
            open={Boolean(menu.element) && menu.type === 'year'}
            onClose={handleMenuClose}
            PaperProps={{ style: { maxHeight: '13.5rem' } }}
          >
            {years.map((individualYear, index) => (
              <MenuItem
                key={index}
                onClick={() => handleMenuItemClick('year', individualYear)}
              >
                {individualYear.label}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      )}
      <Switch>
        <Route path={`${path}/nuevo`}>
          <NewReport
            setIsNewActive={setIsNewActive}
            setNotify={setNotify}
            userData={userData}
          />
        </Route>
      </Switch>
    </>
  );
};

export default Reports;
