import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },

  titleContainer: {
    marginBottom: '70px',
  },

  title: {
    fontSize: '40px',
    fontWeight: 'bold',
  },

  typeContainer: {
    marginBottom: '60px',
  },

  contactContainer: {
    [theme.breakpoints.down('1920')]: {
      paddingLeft: '1.8rem'
    },
  },

  type: {
    fontSize: '25px',
    fontWeight: 'bold',
  },

  icon: {
    boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
    padding: '15px',
    width: '32px',
    height: '32px',
  },

  subtitulo: {
    fontSize: '20px',
    color: '#000000',
  },

  text: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.6)',
  },

  buttonAqua: {
    textTransform: 'none',
    padding: '10px 50px 10px 50px',
    background: '#3ec5bb',
    '&:hover': {
      backgroundColor: '#9be4df',
    },
    color: 'white',
    border: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    marginBottom: '30px',
  },

  divider: {
    backgroundColor: '#3ec5bb',
    margin: '50px auto 50px auto',
    width: '100%',
  },
}));

export default useStyles;
